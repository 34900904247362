import { useApolloClient } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  ApplicationDocumentFragment,
  ApplicationDocumentFragmentDoc,
  useGetApplicationDocumentQuery,
} from '../generated/graphql';
import { Navigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';

const ApplicationDocumentView = ({
  applicationDocument,
}: {
  applicationDocument: ApplicationDocumentFragment;
}) => {
  return (
    <Box>
      <Typography>{applicationDocument.serviceDocument.name}</Typography>
      <Typography>{applicationDocument.signedURL}</Typography>
      <LoadingButton>Sign</LoadingButton>
    </Box>
  );
};

const ApplicationDocumentQuery = ({
  applicationId,
  applicationDocumentId,
}: {
  applicationId: string;
  applicationDocumentId: string;
}) => {
  const { data, loading, error } = useGetApplicationDocumentQuery({
    variables: {
      applicationId: applicationId,
      applicationDocumentId: applicationDocumentId,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (!data?.officerGetApplicationDocument) {
    return <Navigate to={`/applications/${applicationId}`} />;
  }

  return (
    <ApplicationDocumentView
      applicationDocument={data.officerGetApplicationDocument}
    />
  );
};

const ApplicationDocumentCache = ({
  applicationId,
  applicationDocumentId,
}: {
  applicationId: string;
  applicationDocumentId: string;
}) => {
  const client = useApolloClient();

  const applicationDocument = useMemo(() => {
    return client.readFragment<ApplicationDocumentFragment>({
      id: `ApplicationDocument:${applicationDocumentId}`,
      fragment: ApplicationDocumentFragmentDoc,
      fragmentName: 'ApplicationDocument',
    });
  }, [client, applicationDocumentId]);

  if (!applicationDocument) {
    return (
      <ApplicationDocumentQuery
        applicationId={applicationId}
        applicationDocumentId={applicationDocumentId}
      />
    );
  }

  return <ApplicationDocumentView applicationDocument={applicationDocument} />;
};

const ApplicationDocumentParams = () => {
  const { applicationId, applicationDocumentId } = useParams();

  if (!applicationId) {
    return <Navigate to="/applications" />;
  }

  if (!applicationDocumentId) {
    return <Navigate to={`/applications/${applicationId}`} />;
  }

  return (
    <ApplicationDocumentCache
      applicationId={applicationId}
      applicationDocumentId={applicationDocumentId}
    />
  );
};

export { ApplicationDocumentParams as ApplicationDocumentShow };
