import {
  Dialog,
  DialogTitle,
  Stack,
  Button,
  IconButton,
  Typography,
  DialogContent,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { QrMultipleSigning } from '../../../../../common/QrSigning/QrMultipleSigning';
import CloseIcon from '@mui/icons-material/Close';

export const ApplicationDocumentSigningDialog = ({
  open,
  onClose,
  applicationDocumentId,
  signedURL,
  serviceDocumentName,
}: {
  open: boolean;
  onClose: () => void;
  applicationDocumentId: string;
  signedURL: string;
  serviceDocumentName: string;
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          margin: 2,
        },
      }}
    >
      <DialogTitle borderBottom="1px solid rgba(0, 0, 0, 0.12)">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Подписание</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Stack spacing={1} my={2}>
          <Button
            variant="outlined"
            href={signedURL}
            target="_blank"
            endIcon={<OpenInNewIcon />}
            size="large"
          >
            {serviceDocumentName}
          </Button>
        </Stack>
        <QrMultipleSigning
          applicationDocuments={[
            {
              id: applicationDocumentId,
              index: 0,
            },
          ]}
          handleClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
};
