import FormLabel from '@mui/material/FormLabel';
import { Controller } from 'react-hook-form';
import { InputProps } from '../../InputController';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import {
  ProgramFragment,
  useGetProgramsQuery,
} from '../../../../generated/graphql';
import { useMemo } from 'react';

export const InputProgram = ({
  input: {
    label,
    name,
    rules,
    params: { defaultValue, resetOnChange },
  },
  show,
  control,
  getValues,
  setValue,
}: InputProps) => {
  const university = getValues('university');

  const { data, loading, error } = useGetProgramsQuery({
    variables: {
      universityId: university.id,
    },
    skip: show || university == null,
  });

  const options = useMemo<ProgramFragment[]>(() => {
    if (!loading && data) {
      return (
        data.getPrograms
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .map(({ __typename, ...rest }) => rest)
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    }

    return [];
  }, [data, loading]);

  if (error) {
    return <div>Error</div>;
  }

  if (show) {
    return (
      <>
        <FormLabel component="legend">{label}</FormLabel>
        <div>{getValues(name).name}</div>
      </>
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={Object.fromEntries(
        Object.entries(rules).filter(([, value]) => value !== null),
      )}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <FormControl error={!!fieldState.error} fullWidth>
          <Autocomplete
            {...field}
            options={options}
            loading={loading}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Программа"
                error={!!fieldState.error}
                required={!!rules.required?.value}
              />
            )}
            onChange={(_, data) => {
              field.onChange(data);
              resetOnChange?.forEach(({ name, value }) => {
                setValue(name, value);
              });
            }}
          />
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
