import { useState } from 'react';
import {
  ApplicationDocumentFragment,
  ButtonFragment,
} from '../../../../../../generated/graphql';
import { Button } from '@mui/material';
import { StageActionButtonSigningDialog } from './StageActionButtonSigningDialog';

export const StageActionButtonSigning = ({
  applicationId,
  button,
  applicationDocuments,
}: {
  applicationId: string;
  button: ButtonFragment;
  applicationDocuments: ApplicationDocumentFragment[];
}) => {
  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    handleOpen();
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant={
          button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'
        }
        color={
          button.color.toLowerCase() as
            | 'inherit'
            | 'primary'
            | 'secondary'
            | 'success'
            | 'error'
            | 'info'
            | 'warning'
        }
        fullWidth
      >
        {button.label}
      </Button>
      <StageActionButtonSigningDialog
        open={open}
        handleClose={handleClose}
        applicationId={applicationId}
        applicationDocuments={applicationDocuments}
      />
    </>
  );
};
