import { LoadingButton } from '@mui/lab';
import {
  ButtonFragment,
  StageTransitionSystemInstructionFragment,
  StageTransitionSystemInstructionType,
  useUpdateApplicationMutation,
} from '../../../../../../generated/graphql';

export const StageTransitionButtonDirect = ({
  applicationId,
  nextStageId,
  button,
  disabled,
  dormId,
  systemInstructions,
}: {
  applicationId: string;
  nextStageId: string;
  button: ButtonFragment;
  disabled?: boolean;
  dormId?: string;
  systemInstructions: StageTransitionSystemInstructionFragment[];
}) => {
  const [updateApplication, { loading }] = useUpdateApplicationMutation();

  const handleUpdateApplication = () => {
    if (
      systemInstructions.some(
        (systemInstruction) =>
          systemInstruction.type ===
          StageTransitionSystemInstructionType.ReserveDorm,
      ) &&
      !dormId
    ) {
      throw new Error('No dormId provided');
    }

    updateApplication({
      variables: {
        id: applicationId,
        stageId: nextStageId,
        dormId: dormId,
      },
    });
  };

  return (
    <LoadingButton
      loading={loading}
      onClick={handleUpdateApplication}
      variant={
        button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'
      }
      color={
        button.color.toLowerCase() as
          | 'inherit'
          | 'primary'
          | 'secondary'
          | 'success'
          | 'error'
          | 'info'
          | 'warning'
      }
      fullWidth
      disabled={disabled}
    >
      {button.label}
    </LoadingButton>
  );
};
