import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = async (lng: string) => {
    await i18n.changeLanguage(lng);
  };

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    changeLanguage(newAlignment);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={i18n.language}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="ru">RU</ToggleButton>
      <ToggleButton value="kk">KK</ToggleButton>
    </ToggleButtonGroup>
  );
};
