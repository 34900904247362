/* eslint-disable @typescript-eslint/no-explicit-any */
const removeTypename = (value: any) => {
  if (typeof value === 'object' && value !== null) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...rest } = value;
    return rest;
  }

  return value;
};

export const updateDefaultValuesWithData = (obj: any, data: any) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (key.includes('.')) {
      const [parent, child] = key.split('.');

      if (data[parent] && data[parent][child] !== undefined) {
        return {
          ...acc,
          [parent]: {
            // @ts-expect-error fff
            ...acc[parent],
            [child]: data[parent][child],
          },
        };
      }

      return acc;
    }

    return {
      ...acc,
      [key]: removeTypename(data[key]) || value,
    };
  }, {});
};
