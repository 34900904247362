import {
  Box,
  Link,
  Breadcrumbs as MUIBreadcrumbs,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { managerRoutes, signerRoutes } from '../../config/router/officerRouter';
import { useMemo } from 'react';
import { useGetCurrentUserQuery } from '../../generated/graphql';
import { FilterMenu } from './FilterMenu';
import { BulkSigningDialogButton } from '../../pages/application-document/overview/components';

export const Breadcrumbs = () => {
  const { data } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });

  const routes = useMemo(() => {
    if (!data) {
      throw new Error('Current user is missing in cache');
    }

    if (!data.officerGetCurrentUser.officer) {
      throw new Error('Officer is missing in cache');
    }

    return data.officerGetCurrentUser.officer.position.managingServices.length >
      0
      ? managerRoutes
      : signerRoutes;
  }, [data]);

  const breadcrumbs = useReactRouterBreadcrumbs(routes);

  return (
    <Box
      sx={(theme) => ({
        position: 'sticky',
        top: {
          xs: 56,
          sm: 64,
        },
        zIndex: theme.zIndex.appBar,
      })}
    >
      <Box
        sx={{
          px: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid rgb(223, 224, 225)',
          bgcolor: '#ffffff',
        }}
      >
        <MUIBreadcrumbs
          sx={{
            minHeight: '56px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {breadcrumbs.map(({ location, breadcrumb, match, key }) =>
            location.pathname === match.pathname ? (
              <Typography key={key} color="text.primary">
                {breadcrumb}
              </Typography>
            ) : (
              <Link
                key={key}
                underline="hover"
                color="inherit"
                component={RouterLink}
                to={match}
              >
                {breadcrumb}
              </Link>
            ),
          )}
        </MUIBreadcrumbs>
        {breadcrumbs.length === 1 &&
          breadcrumbs[breadcrumbs.length - 1].key === '/applications' &&
          data!.officerGetCurrentUser.officer!.position.managingServices.some(
            (service) => service.code === 'DORM-CHECK-IN',
          ) && <FilterMenu />}
        {breadcrumbs.length === 1 &&
          breadcrumbs[breadcrumbs.length - 1].key === '/documents' && (
            <BulkSigningDialogButton />
          )}
      </Box>
    </Box>
  );
};
