import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  DocumentFragment,
  DocumentFragmentDoc,
  InputType,
  StudentDocumentSnapshotFragment,
} from '../../generated/graphql';
import { VerificationStatus } from '../VerificationStatus/VerificationStatus';
import { StudentDocumentCardVerificationBar } from './StudentDocumentCardVerificationBar';
import { formatInputValue } from './utils';
import { getValueByPath } from '../../utils';
import { useFragment } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const StudentDocumentCard = ({
  studentDocument,
  review,
}: {
  studentDocument: StudentDocumentSnapshotFragment;
  review?: {
    applicationId: string;
  };
}) => {
  const { data, complete } = useFragment<DocumentFragment>({
    fragment: DocumentFragmentDoc,
    fragmentName: 'Document',
    from: {
      category: studentDocument.data.category,
      __typename: 'Document',
    },
  });

  if (!complete) {
    throw Error('Document not found');
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={1}>
          <Box display="flex" alignItems="center">
            <Typography fontWeight="500">
              {studentDocument.data.name}
            </Typography>
            <VerificationStatus verification={studentDocument.verification} />
          </Box>
          {studentDocument.verification?.error && (
            <Alert
              severity={
                studentDocument.verification.error.corrected
                  ? 'warning'
                  : 'error'
              }
            >
              <AlertTitle>
                {studentDocument.verification.error.corrected
                  ? 'Обнаруженные ранее ошибки'
                  : 'Обнаружены ошибки'}
              </AlertTitle>
              {studentDocument.verification.error.message}
            </Alert>
          )}
          {data.inputs
            .filter((input) => {
              if (input.type === InputType.File) {
                return null;
              }

              if (
                input.params.renderIfPresent &&
                !getValueByPath(
                  studentDocument.data.data,
                  input.params.renderIfPresent,
                )
              ) {
                return false;
              }

              if (input.params.renderIf) {
                const allConditionsMet = input.params.renderIf.every(
                  (condition) => {
                    return (
                      getValueByPath(
                        studentDocument.data.data,
                        condition.name,
                      ) === condition.value
                    );
                  },
                );

                if (!allConditionsMet) {
                  return false;
                }
              }

              return true;
            })
            // .sort((a, b) => a.position - b.position)
            .map((input) => {
              return (
                <Box key={input.name}>
                  <FormLabel>{input.label}</FormLabel>
                  <Typography>
                    {formatInputValue(
                      studentDocument.data.data[input.name],
                      input.type,
                      input.params.options,
                    )}
                  </Typography>
                </Box>
              );
            })}

          <Box>
            <Grid container spacing={1}>
              {studentDocument.data.files.map(({ name, signedUrl }, index) => (
                <Grid item xs={12} sm="auto" key={index}>
                  <Button
                    fullWidth
                    variant="outlined"
                    endIcon={<OpenInNewIcon />}
                    size="small"
                    href={signedUrl}
                    target="_blank"
                  >
                    {name}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </CardContent>
      {review && (
        <StudentDocumentCardVerificationBar
          applicationId={review.applicationId}
          documentCategory={studentDocument.data.category}
          verification={studentDocument.verification}
        />
      )}
    </Card>
  );
};
