import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { InputFragment } from '../../generated/graphql';
import { OfficerFormNew } from './OfficerFormNew';
import { OfficerFormCardShow } from './OfficerFormCardShow';

export const OfficerFormCard = ({
  applicationId,
  data,
  formData,
  inputs,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
  applicationId: string;
  inputs: InputFragment[];
}) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={1}>
          <Box display="flex" alignItems="center">
            <Typography fontWeight="500">Дополнения</Typography>
          </Box>

          {data === null ? (
            <OfficerFormNew
              inputs={inputs}
              applicationId={applicationId}
              formData={formData}
            />
          ) : (
            <OfficerFormCardShow
              inputs={inputs}
              applicationId={applicationId}
              data={data}
              formData={formData}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
