import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const SignInNCALayer = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        fontSize={{
          xs: 20,
          md: 36,
        }}
        textAlign="center"
        gutterBottom
      >
        {t('sign-in.NCALayer.title')}
      </Typography>
    </Box>
  );
};
