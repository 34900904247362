import { Box, Button, Link } from '@mui/material';
import {
  ApplicationDocumentFragment,
  ApplicationDocumentSigningState,
} from '../../../generated/graphql';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from '@mui/x-data-grid';
import { ruRU } from '@mui/x-data-grid/locales';
import { useMemo, useState } from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale/ru';
import { Link as RouterLink } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LoadingButton } from '@mui/lab';
import { ApplicationDocumentSigningDialog } from './components/SigningDialog';

const APPLICATION_DOCUMENTS_TABLE_COLUMNS: GridColDef[] = [
  {
    field: 'iin',
    headerName: 'ИИН',
    width: 150,
    hideable: true,
  },
  {
    field: 'fullName',
    headerName: 'ФИО',
    width: 300,
    hideable: true,
  },
  {
    field: 'serviceName',
    headerName: 'Услуга',
    width: 250,
    hideable: true,
  },
  {
    field: 'referenceNumber',
    headerName: 'Номер документа',
    width: 170,
    hideable: true,
  },
  {
    field: 'serviceDocumentName',
    headerName: 'Документ',
    width: 250,
    hideable: false,
    renderCell: (
      params: GridRenderCellParams<ApplicationDocumentRowType, string>,
    ) => (
      <Link
        component={RouterLink}
        to={params.row.signedURL}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        target="_blank"
      >
        {params.value}
        <OpenInNewIcon sx={{ ml: '6px', fontSize: '16px' }} />
      </Link>
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Документ создан',
    type: 'dateTime',
    valueGetter: (value) => parseISO(value),
    valueFormatter: (value) =>
      formatDistanceToNow(value, {
        addSuffix: true,
        locale: ru,
      }),
    width: 200,
    hideable: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Документ изменен',
    type: 'dateTime',
    valueGetter: (value) => parseISO(value),
    valueFormatter: (value) =>
      formatDistanceToNow(value, {
        addSuffix: true,
        locale: ru,
      }),
    width: 200,
    hideable: true,
  },
  {
    field: 'applicationCreatedAt',
    headerName: 'Заявление создано',
    type: 'dateTime',
    valueGetter: (value) => parseISO(value),
    valueFormatter: (value) =>
      formatDistanceToNow(value, {
        addSuffix: true,
        locale: ru,
      }),
    width: 200,
    hideable: true,
  },
  {
    field: 'id',
    headerName: 'ID документа',
    width: 300,
    hideable: true,
  },
];

type ApplicationDocumentRowType = {
  id: string;
  fullName: string;
  iin: string;
  serviceName: string;
  serviceDocumentName: string;
  signedURL: string;
  fileCardUrl?: string | null;
  referenceNumber?: string | null;
  signingButton: {
    isSignedByCurrentUser: boolean;
    handleOpenSigningDialog: ({
      applicationDocumentId,
      signedURL,
      serviceDocumentName,
    }: {
      applicationDocumentId: string;
      signedURL: string;
      serviceDocumentName: string;
    }) => void;
  };
  createdAt: string;
  updatedAt: string;
  applicationCreatedAt: string;
};

export const ApplicationDocumentOverviewTableView = ({
  applicationDocuments,
  officerIIN,
  state,
}: {
  applicationDocuments: ApplicationDocumentFragment[];
  officerIIN: string;
  state: ApplicationDocumentSigningState;
}) => {
  const [signingDialog, setSigningDialog] = useState<{
    applicationDocumentId: string;
    signedURL: string;
    serviceDocumentName: string;
  } | null>(null);

  const handleOpenSigningDialog = ({
    applicationDocumentId,
    signedURL,
    serviceDocumentName,
  }: {
    applicationDocumentId: string;
    signedURL: string;
    serviceDocumentName: string;
  }) => {
    setSigningDialog({
      applicationDocumentId,
      signedURL,
      serviceDocumentName,
    });
  };

  const handleCloseSigningDialog = () => {
    setSigningDialog(null);
  };

  const rows = useMemo<GridRowsProp>(
    () =>
      applicationDocuments.map<ApplicationDocumentRowType>(
        (applicationDocument) => ({
          id: applicationDocument.id,
          fullName: applicationDocument.application.student.user.fullName,
          iin: applicationDocument.application.student.user.iin,
          serviceName: applicationDocument.serviceDocument.service.name,
          serviceDocumentName: applicationDocument.serviceDocument.name,
          signedURL: applicationDocument.signedURL,
          referenceNumber: applicationDocument.referenceNumber,
          fileCardUrl: applicationDocument.fileCardUrl,
          signingButton: {
            isSignedByCurrentUser:
              applicationDocument.sigexDocument?.signatures.find(
                (signature) => signature.user.iin === officerIIN,
              )
                ? true
                : false,
            handleOpenSigningDialog,
          },
          createdAt: applicationDocument.createdAt,
          updatedAt: applicationDocument.updatedAt,
          applicationCreatedAt: applicationDocument.application.createdAt,
        }),
      ),
    [applicationDocuments, officerIIN],
  );

  const columns = useMemo<GridColDef[]>(() => {
    const res = APPLICATION_DOCUMENTS_TABLE_COLUMNS.slice();

    switch (state) {
      case ApplicationDocumentSigningState.Signed:
        res.splice(5, 0, {
          field: 'fileCardUrl',
          headerName: 'Карточка',
          width: 250,
          hideable: false,
          renderCell: (
            params: GridRenderCellParams<
              ApplicationDocumentRowType,
              ApplicationDocumentRowType['fileCardUrl']
            >,
          ) =>
            params.value ? (
              <Link
                component={RouterLink}
                to={params.value}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                target="_blank"
              >
                Карточка документа
                <OpenInNewIcon sx={{ ml: '6px', fontSize: '16px' }} />
              </Link>
            ) : null,
        });
        break;
      case ApplicationDocumentSigningState.Signing:
        res.splice(5, 0, {
          field: 'signingButton',
          headerName: 'Подписание',
          width: 150,
          hideable: false,
          renderCell: (
            params: GridRenderCellParams<
              ApplicationDocumentRowType,
              ApplicationDocumentRowType['signingButton']
            >,
          ) =>
            params.value!.isSignedByCurrentUser ? (
              <Button disabled fullWidth size="small">
                Подписано
              </Button>
            ) : (
              <LoadingButton
                onClick={() =>
                  params.value!.handleOpenSigningDialog({
                    applicationDocumentId: params.row.id,
                    signedURL: params.row.signedURL,
                    serviceDocumentName: params.row.serviceDocumentName,
                  })
                }
                variant="outlined"
                fullWidth
                size="small"
              >
                Подписать
              </LoadingButton>
            ),
        });
        break;
      default:
        break;
    }

    return res;
  }, [state]);

  return (
    <Box width="100%">
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        autoHeight
        pageSizeOptions={[10, 20, 50]}
        disableRowSelectionOnClick
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
      />
      {signingDialog && (
        <ApplicationDocumentSigningDialog
          open
          onClose={handleCloseSigningDialog}
          applicationDocumentId={signingDialog.applicationDocumentId}
          signedURL={signingDialog.signedURL}
          serviceDocumentName={signingDialog.serviceDocumentName}
        />
      )}
    </Box>
  );
};
