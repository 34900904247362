import {
  InputDate,
  InputFaculty,
  InputProgram,
  InputRadio,
  InputSpeciality,
  InputText,
  InputTextarea,
  InputUniversity,
} from '../components';
import { InputType } from '../../../generated/graphql';
import { InputProps } from '../InputController';
import { ReactElement } from 'react';

export const INPUT_DICT: {
  [key in InputType]: null | ((props: InputProps) => ReactElement);
} = {
  [InputType.Checkbox]: null,
  [InputType.Date]: InputDate,
  [InputType.Document]: null,
  [InputType.Faculty]: InputFaculty,
  [InputType.File]: null,
  [InputType.Number]: null,
  [InputType.PhoneNumber]: null,
  [InputType.Program]: InputProgram,
  [InputType.Radio]: InputRadio,
  [InputType.Region]: null,
  [InputType.Select]: null,
  [InputType.Speciality]: InputSpeciality,
  [InputType.Text]: InputText,
  [InputType.Textarea]: InputTextarea,
  [InputType.University]: InputUniversity,
};
