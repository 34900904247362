import { UseFormReturn } from 'react-hook-form';

import { InputFragment, InputRenderIfFragment } from '../../generated/graphql';
import { INPUT_DICT } from './constants';
import { Grid } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface InputProps extends UseFormReturn<any, object> {
  input: InputFragment;
  show?: boolean;
  disabled?: boolean;
}

interface InputControllerProps extends InputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
}

export const InputController = ({
  input,
  formData,
  ...methods
}: InputControllerProps) => {
  if (
    input.params.renderIfPresent &&
    !methods.watch(input.params.renderIfPresent)
  ) {
    return null;
  }

  if (input.params.renderIf) {
    const [formIf, renderIf] = input.params.renderIf.reduce<
      [InputRenderIfFragment[], InputRenderIfFragment[]]
    >(
      ([formIf, renderIf], cur) =>
        cur.name.includes('form.')
          ? [[...formIf, cur], renderIf]
          : [formIf, [...renderIf, cur]],
      [[], []],
    );

    const allFormConditionsMet = formIf.every((condition) => {
      // const parentInput = methods.show
      //   ? methods.getValues(condition.name)
      //   : methods.watch(condition.name);

      return formData[condition.name.replace('form.', '')] === condition.value;
    });

    if (!allFormConditionsMet) {
      return null;
    }

    const allConditionsMet = renderIf.every((condition) => {
      const parentInput = methods.show
        ? methods.getValues(condition.name)
        : methods.watch(condition.name);

      return parentInput === condition.value;
    });

    if (!allConditionsMet) {
      return null;
    }
  }

  const InputComponent = INPUT_DICT[input.type];

  if (!InputComponent) {
    return <div>Input of type {input.type} cannot be rendered</div>;
  }

  return (
    <Grid item xs={12}>
      <InputComponent input={input} {...methods} />
    </Grid>
  );
};
