import { CircularProgress } from '@mui/material';

export const QrCode = ({
  qrCode,
  signing,
}: {
  qrCode: string;
  signing: boolean;
}) => {
  return signing ? (
    <CircularProgress />
  ) : (
    <img src={`data:image/jpeg;base64,${qrCode}`} />
  );
};
