import { DrawerLinks } from './DrawerLinks';
import { Box, Divider, Toolbar } from '@mui/material';
import { UserInfo } from './UserInfo';
import { useGetCurrentUserQuery } from '../../generated/graphql';

export const DrawerContent = ({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void;
}) => {
  const { data } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });

  if (!data) {
    throw new Error('Current user is missing in cache');
  }

  if (!data.officerGetCurrentUser.officer) {
    throw new Error('Officer is missing in cache');
  }

  const { firstName, middleName, lastName, officer } =
    data.officerGetCurrentUser;

  return (
    <Box>
      <Toolbar />
      <UserInfo
        firstName={firstName}
        middleName={middleName}
        lastName={lastName}
        positionName={officer.position.name}
      />
      <Divider />
      <DrawerLinks
        isServiceManager={officer.position.managingServices.length > 0}
        isSigner={officer.position.signingServiceDocuments.length > 0}
        handleDrawerToggle={handleDrawerToggle}
      />
    </Box>
  );
};
