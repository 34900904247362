import { VerificationFragment } from '../../generated/graphql';
import { VerficationBar } from './VerificationBar';

export const VerificationBarController = ({
  verification,
  handleVerify,
  loading,
}: {
  verification?: VerificationFragment | null;
  handleVerify: (errorMessage?: string) => Promise<void>;
  loading: boolean;
}) => {
  const handleValidate = async (errorMessage?: string) => {
    handleVerify(errorMessage);
  };

  return (
    <VerficationBar
      verification={verification}
      loading={loading}
      handleValidate={handleValidate}
    />
  );
};
