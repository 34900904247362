/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputOptionFragment, InputType } from '../../generated/graphql';

const { Checkbox, Radio, Select, Text, Region } = InputType;

const regionDict = {
  city_astana: 'Астана',
  city_almaty: 'Алматы',
  city_shymkent: 'Шымкент',
  region_abai: 'Абайская Область',
  region_aqmola: 'Акмолинская Область',
  region_aqtobe: 'Актюбинская Область',
  region_almaty: 'Алматинская Область',
  region_atyrau: 'Атырауская Область',
  region_east: 'Восточно-Казахстанская Область',
  region_jambyl: 'Жамбылская Область',
  region_jetisu: 'Жетысуская Область',
  region_west: 'Западно-Казахстанская Область',
  region_qaraghandy: 'Карагандинская Область',
  region_qostanai: 'Костанайская Область',
  region_qyzylorda: 'Кызылординская Область',
  region_manghystau: 'Мангыстауская Область',
  region_pavlodar: 'Павлодарская Область',
  region_north: 'Северо-Казахстанская Область',
  region_turkistan: 'Туркестанская Область',
  region_ulytau: 'Улытауская Область',
};

export const formatInputValue = (
  value: any,
  inputType: InputType,
  options?: InputOptionFragment[] | null,
): string => {
  let result = '';

  switch (inputType) {
    case Checkbox:
      result = value ? 'Да' : 'Нет';
      break;
    case Radio:
    case Select:
      result =
        options?.find((option) => value === option.value)?.label ?? 'ОШИБКА';
      break;
    case Region:
      // @ts-expect-error fff
      result = regionDict[value];
      break;
    case Text:
    default:
      result = value;
      break;
  }

  return result;
};
