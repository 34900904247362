import { Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export const DrawerLinks = ({
  isServiceManager,
  isSigner,
  handleDrawerToggle,
}: {
  isServiceManager: boolean;
  isSigner: boolean;
  handleDrawerToggle: () => void;
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const links = useMemo(() => {
    const res = [];

    if (isServiceManager) {
      res.push({
        path: '/applications',
        label: 'sign-in.applicationText',
      });
    }

    if (isSigner) {
      res.push({
        path: '/documents',
        label: 'sign-in.documentText',
      });
    }

    return res;
  }, [isServiceManager, isSigner]);

  return (
    <Box sx={{ overflow: 'auto' }}>
      <List>
        {links.map(({ label, path }) => (
          <ListItem key={path} disablePadding>
            <ListItemButton
              selected={pathname.includes(path)}
              component={Link}
              to={path}
              onClick={handleDrawerToggle}
            >
              <ListItemText primary={t(label)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
