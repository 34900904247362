import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  InputFragment,
  useUpdateApplicationOfficerFormMutation,
} from '../../generated/graphql';
import { useForm } from 'react-hook-form';
import { extractDefaultValuesFromInputs } from '../../utils';
import { Input } from '../Input';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack } from '@mui/material';

export const OfficerFormNew = ({
  inputs,
  formData,
  applicationId,
}: {
  inputs: InputFragment[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
  applicationId: string;
}) => {
  const [update, { loading }] = useUpdateApplicationOfficerFormMutation();

  const methods = useForm({
    defaultValues: extractDefaultValuesFromInputs(inputs),
  });

  const onSubmit = (data: unknown) => {
    update({
      variables: {
        applicationId,
        data,
      },
      update(cache, result) {
        const officerFormData =
          result.data?.officerUpdateApplicationOfficerForm;

        if (!officerFormData) {
          throw Error('Something went wrong');
        }

        cache.updateQuery<GetApplicationQuery, GetApplicationQueryVariables>(
          {
            query: GetApplicationDocument,
            variables: {
              id: applicationId,
            },
          },
          (data) => {
            if (data !== null) {
              return {
                officerGetApplication: {
                  ...data.officerGetApplication,
                  data: {
                    ...data.officerGetApplication.data,
                    officerFormData,
                  },
                },
              };
            }
          },
        );
      },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Box>
          <Grid container spacing={2}>
            {inputs.map((input) => (
              <Grid item xs={12} key={input.name}>
                <Input
                  input={input}
                  disabled={false}
                  formData={formData}
                  {...methods}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                fullWidth
              >
                Сохранить
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </form>
  );
};
