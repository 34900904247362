import { Grid } from '@mui/material';
import { ApplicationDocumentFragment } from '../../../generated/graphql';
import { ApplicationDocumentCard } from './components/Card/ApplicationDocumentCard';

export const ApplicationDocumentOverviewCardView = ({
  applicationDocuments,
  officerIIN,
}: {
  applicationDocuments: ApplicationDocumentFragment[];
  officerIIN: string;
}) => {
  return (
    <Grid container spacing={2}>
      {applicationDocuments.map((applicationDocument) => (
        <Grid item key={applicationDocument.id} xs={12} md={6} lg={4} xl={3}>
          <ApplicationDocumentCard
            applicationDocument={applicationDocument}
            officerIIN={officerIIN}
          />
        </Grid>
      ))}
    </Grid>
  );
};
