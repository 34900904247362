import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  InputFragment,
  InputType,
  StudentDataFragment,
  useCorrectStudentFormMutation,
} from '../../generated/graphql';
import { useForm } from 'react-hook-form';
import {
  extractDefaultValuesFromInputs,
  updateDefaultValuesWithData,
} from '../../utils';
import { Input } from '../Input';
import { Box, Grid } from '@mui/material';

export const StudentCardEdit = ({
  data,
  inputs,
  applicationId,
  handleShow,
}: {
  data: StudentDataFragment;
  inputs: InputFragment[];
  applicationId: string;
  handleShow: () => void;
}) => {
  const methods = useForm<StudentDataFragment>({
    defaultValues: {
      university: {
        id: data.university.id,
        name: data.university.name,
        code: data.university.code,
      },
      ...updateDefaultValuesWithData(
        extractDefaultValuesFromInputs(inputs),
        data,
      ),
    },
  });

  const [correctStudentForm, { loading }] = useCorrectStudentFormMutation();

  const onSubmit = (data: StudentDataFragment) => {
    correctStudentForm({
      variables: {
        applicationId: applicationId,
        data: data,
      },
      update(cache, result) {
        const newStudentSnapshot = result.data?.officerCorrectStudentForm;

        if (!newStudentSnapshot) {
          throw Error('Something went wrong');
        }

        cache.updateQuery<GetApplicationQuery, GetApplicationQueryVariables>(
          {
            query: GetApplicationDocument,
            variables: {
              id: applicationId,
            },
          },
          (data) => {
            if (data !== null) {
              return {
                officerGetApplication: {
                  ...data.officerGetApplication,
                  data: {
                    ...data.officerGetApplication.data,
                    student: newStudentSnapshot,
                  },
                },
              };
            }
          },
        );
      },
      onCompleted() {
        handleShow();
      },
    });
  };

  return (
    <Box>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        id="STUDENT_FORM"
      >
        <Grid container spacing={2}>
          <Input
            formData={{}}
            input={{
              name: 'university',
              type: InputType.University,
              position: 0,
              label: 'Университет',
              params: {
                defaultValue: null,
              },
              rules: {
                required: {
                  value: true,
                  message: 'Обязательное поле',
                },
              },
            }}
            show
            disabled
            {...methods}
          />

          {inputs.map((input, index) => (
            <Input
              formData={{}}
              key={index}
              input={input}
              disabled={loading}
              {...methods}
            />
          ))}
        </Grid>
      </form>
    </Box>
  );
};
