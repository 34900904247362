import { InMemoryCache } from '@apollo/client';

export const cache = new InMemoryCache({
  typePolicies: {
    Document: {
      keyFields: ['category'],
    },
    InputRuleRegExp: {
      fields: {
        value: {
          read(value) {
            return new RegExp(value, 'i');
          },
        },
      },
    },
  },
});
