import { Stack, Typography, Box } from '@mui/material';
import { DormApplicationFragment } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';

export const ApplicationDormInfo = ({
  dormApplication,
}: {
  dormApplication: DormApplicationFragment;
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1} p={2} bgcolor="white">
      <Typography fontWeight="500">Информация по общежитию</Typography>
      <Box>
        <Typography>Общежитие</Typography>
        <Typography>{dormApplication.dorm.data.name}</Typography>
      </Box>
      <Box>
        <Typography>Адрес</Typography>
        <Typography>{dormApplication.dorm.data.address}</Typography>
      </Box>
      <Box>
        <Typography>Статус</Typography>
        <Typography>
          {t(`dormApplication.status.${dormApplication.status}`)}
        </Typography>
      </Box>
    </Stack>
  );
};
