import { Box, Typography } from '@mui/material';

export const UserInfo = ({
  firstName,
  middleName,
  lastName,
  positionName,
}: {
  firstName: string;
  middleName: string;
  lastName: string;
  positionName: string;
}) => {
  const initials = `${lastName} ${firstName[0]}.${middleName[0]}.`;

  return (
    <Box p={2}>
      <Typography>{initials}</Typography>
      <Typography>{positionName}</Typography>
    </Box>
  );
};
