import FormLabel from '@mui/material/FormLabel';
import { Controller } from 'react-hook-form';
import { InputProps } from '../../InputController';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';

export const InputRadio = ({
  input: {
    label,
    name,
    rules,
    params: { defaultValue, options, resetOnChange },
  },
  show,
  disabled,
  control,
  getValues,
  setValue,
}: InputProps) => {
  if (show) {
    return (
      <>
        <FormLabel component="legend">{label}</FormLabel>
        <div>
          {options?.find((option) => getValues(name) === option.value)?.label}
        </div>
      </>
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={Object.fromEntries(
        Object.entries(rules).filter(([, value]) => value !== null),
      )}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <FormControl error={!!fieldState.error} fullWidth>
          <FormLabel required={rules?.required?.value}>{label}</FormLabel>
          <RadioGroup
            row
            {...field}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              field.onChange((event.target as HTMLInputElement).value);

              resetOnChange?.forEach(({ name, value }) => {
                setValue(name, value);
              });
            }}
          >
            {options?.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                disabled={disabled}
                label={option.label}
              />
            ))}
          </RadioGroup>
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
