import { useParams } from 'react-router-dom';
import { ApplicationShowQuery } from './ApplicationShowQuery';

export const ApplicationShowParams = () => {
  const { applicationId } = useParams();

  if (!applicationId) {
    throw new Error('applicationId not found');
  }

  return <ApplicationShowQuery applicationId={applicationId} />;
};
