import { Navigate, createBrowserRouter } from 'react-router-dom';
import { SignIn } from '../../pages/guest/sign-in';

const guestRouter = createBrowserRouter([
  {
    path: '/sign-in',
    element: <SignIn />,
  },
  {
    path: '*',
    element: <Navigate to="/sign-in" />,
  },
]);

export default guestRouter;
