import {
  DocumentCategory,
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  VerificationFragment,
  useValidateApplicationStudentDocumentMutation,
} from '../../generated/graphql';
import { VerificationBar } from '../VerificationBar';

export const StudentDocumentCardVerificationBar = ({
  applicationId,
  documentCategory,
  verification,
}: {
  applicationId: string;
  documentCategory: DocumentCategory;
  verification?: VerificationFragment | null;
}) => {
  const [validateApplicationStudentDocument, { loading }] =
    useValidateApplicationStudentDocumentMutation();

  const handleVerify = async (errorMessage?: string) => {
    await validateApplicationStudentDocument({
      variables: {
        applicationId,
        documentCategory,
        errorMessage,
      },
      update(cache, result) {
        const newVerification =
          result.data?.officerValidateApplicationStudentDocument;

        if (!newVerification) {
          throw Error('Something went wrong');
        }

        cache.updateQuery<GetApplicationQuery, GetApplicationQueryVariables>(
          {
            query: GetApplicationDocument,
            variables: {
              id: applicationId,
            },
          },
          (data) => {
            if (data !== null) {
              return {
                officerGetApplication: {
                  ...data.officerGetApplication,
                  data: {
                    ...data.officerGetApplication.data,
                    studentDocuments:
                      data.officerGetApplication.data.studentDocuments.map(
                        (studentDocument) =>
                          studentDocument.data.category === documentCategory
                            ? {
                                ...studentDocument,
                                verification: newVerification,
                              }
                            : studentDocument,
                      ),
                  },
                },
              };
            }
          },
        );
      },
    });
  };

  return (
    <VerificationBar
      verification={verification}
      handleVerify={handleVerify}
      loading={loading}
    />
  );
};
