import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SignInEmail, SignInNCALayer, SignInQr } from './components';
import { LanguageSwitcher } from '../../../common';

type SignInMethod = 'Email' | 'Qr' | 'NCALayer';

const SIGN_IN_METHODS: SignInMethod[] = ['Email', 'Qr'];

export const SignIn = () => {
  const [signInMethod, setSignInMethod] = useState<SignInMethod>('Email');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  useEffect(() => {
    if (isMobile && signInMethod === 'NCALayer') {
      setSignInMethod('Email');
    }
  }, [isMobile, signInMethod]);

  const availableMethods = useMemo(() => {
    return SIGN_IN_METHODS.filter((method) => {
      if (isMobile && method === 'NCALayer') {
        return false;
      }

      return method !== signInMethod;
    });
  }, [signInMethod, isMobile]);

  const renderSignIn = () => {
    switch (signInMethod) {
      case 'Email':
        return <SignInEmail />;
      case 'NCALayer':
        return <SignInNCALayer />;
      case 'Qr':
        return <SignInQr />;
      default:
        return null;
    }
  };

  const renderButtons = () => {
    return (
      <Box>
        <Divider sx={{ py: 4 }}>{t('sign-in.divider')}</Divider>
        <Stack spacing={1}>
          {availableMethods.map((method) => (
            <SignInMethodButton key={method} method={method} />
          ))}
        </Stack>
      </Box>
    );
  };

  const SignInMethodButton = ({ method }: { method: SignInMethod }) => (
    <Button
      fullWidth
      variant="outlined"
      size="large"
      onClick={() => {
        setSignInMethod(method);
      }}
    >
      {t(`sign-in.buttons.${method === 'Qr' && isMobile ? 'eGov' : method}`)}
    </Button>
  );

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <LanguageSwitcher />
      </Box>
      <Container maxWidth="sm">
        <Box>
          {renderSignIn()}
          {renderButtons()}
        </Box>
      </Container>
    </Box>
  );
};
