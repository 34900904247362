import { Navigate, createBrowserRouter } from 'react-router-dom';
import { ApplicationOverview } from '../../pages/applications/overview';
import { OfficerLayout } from '../../components/OfficerLayout/OfficerLayout';
import { ApplicationShow } from '../../pages/applications/show';
import { ApplicationDocumentShow } from '../../pages/ApplicationDocumentShow';
import { ApplicationDocumentOverview } from '../../pages/application-document/overview';

export const signerRoutes = [
  {
    path: '/',
    element: <OfficerLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/documents" />,
        breadcrumb: null,
      },
      {
        path: '/documents',
        element: <ApplicationDocumentOverview />,
        breadcrumb: 'Документы',
      },
      {
        path: '*',
        element: <Navigate to="/documents" />,
      },
    ],
  },
];

export const managerRoutes = [
  {
    path: '/',
    element: <OfficerLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/applications" />,
        breadcrumb: null,
      },
      {
        path: '/applications',
        element: <ApplicationOverview />,
        breadcrumb: 'Заявления',
      },
      {
        path: '/applications/:applicationId',
        element: <ApplicationShow />,
        breadcrumb: 'Заявление',
      },
      {
        path: '/applications/:applicationId/:applicationDocumentId',
        element: <ApplicationDocumentShow />,
        breadcrumb: 'Документ заявления',
      },
      {
        path: '/documents',
        element: <ApplicationDocumentOverview />,
        breadcrumb: 'Документы',
      },
      {
        path: '*',
        element: <Navigate to="/applications" />,
      },
    ],
  },
];

export const signerRouter = createBrowserRouter(signerRoutes);

export const managerRouter = createBrowserRouter(managerRoutes);
