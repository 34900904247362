import Tooltip from '@mui/material/Tooltip';
import VerifiedIcon from '@mui/icons-material/Verified';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BuildOutlined from '@mui/icons-material/BuildOutlined';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

import { VerificationFragment } from '../../generated/graphql';

export const VerificationStatus = ({
  verification,
}: {
  verification?: VerificationFragment | null;
}) => {
  const renderVerificationStatus = () => {
    if (!verification) {
      return (
        <Tooltip title="Данные ожидают подтверждения" placement="right">
          <AccessTimeIcon sx={{ ml: 1, fontSize: 20 }} color="primary" />
        </Tooltip>
      );
    }

    if (!verification.error) {
      return (
        <Tooltip title="Данные были подтверждены" placement="right">
          <VerifiedIcon sx={{ ml: 1, fontSize: 20 }} color="success" />
        </Tooltip>
      );
    }

    if (verification.error.corrected) {
      return (
        <Tooltip title="Иправлено студентом" placement="right">
          <BuildOutlined sx={{ ml: 1, fontSize: 20 }} color="warning" />
        </Tooltip>
      );
    }

    return (
      <Tooltip title="В данных были обнаружены ошибки" placement="right">
        <ErrorOutline sx={{ ml: 1, fontSize: 20 }} color="error" />
      </Tooltip>
    );
  };

  return renderVerificationStatus();
};
