import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  VerificationFragment,
  useValidateApplicationStudentMutation,
} from '../../generated/graphql';
import { VerificationBarNoErrorForm } from '../VerificationBarNoErrorForm';

export const StudentCardVerificationBar = ({
  applicationId,
  verification,
}: {
  applicationId: string;
  verification?: VerificationFragment | null;
}) => {
  const [validateApplicationStudent, { loading }] =
    useValidateApplicationStudentMutation();

  const handleVerify = async (errorMessage?: string) => {
    await validateApplicationStudent({
      variables: {
        applicationId,
        errorMessage,
      },
      update(cache, result) {
        const newVerification = result.data?.officerValidateApplicationStudent;

        if (!newVerification) {
          throw Error('Something went wrong');
        }

        cache.updateQuery<GetApplicationQuery, GetApplicationQueryVariables>(
          {
            query: GetApplicationDocument,
            variables: {
              id: applicationId,
            },
          },
          (data) => {
            if (data !== null) {
              return {
                officerGetApplication: {
                  ...data.officerGetApplication,
                  data: {
                    ...data.officerGetApplication.data,
                    student: {
                      ...data.officerGetApplication.data.student,
                      verification: newVerification,
                    },
                  },
                },
              };
            }
          },
        );
      },
    });
  };

  return (
    <VerificationBarNoErrorForm
      verification={verification}
      handleVerify={handleVerify}
      loading={loading}
    />
  );
};
