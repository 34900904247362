import { Stack } from '@mui/material';
import { DegreeFilter } from './DegreeFilter';
import { DormApplicationFilters } from './DormApplicationFilters';

export const FilterMenu = () => {
  return (
    <Stack direction="row" spacing={2}>
      <DegreeFilter />
      <DormApplicationFilters />
    </Stack>
  );
};
