import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { BulkSigningDialog } from './BulkSigningDialog';

export const BulkSigningDialogButton = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    handleOpen();
  };

  return (
    <Box>
      <Button onClick={handleClick} variant="outlined" size="small">
        Подписать все
      </Button>
      {open && <BulkSigningDialog onClose={handleClose} />}
    </Box>
  );
};
