import FormLabel from '@mui/material/FormLabel';
import { Controller } from 'react-hook-form';
import { InputProps } from '../../InputController';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ru } from 'date-fns/locale';
import { formatISO, parseISO } from 'date-fns';

export const InputDate = ({
  input: { label, name, rules },
  show,
  disabled,
  control,
  getValues,
}: InputProps) => {
  if (show) {
    return (
      <>
        <FormLabel component="legend">{label}</FormLabel>
        <div>{getValues(name)}</div>
      </>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <Controller
        control={control}
        name={name}
        rules={Object.fromEntries(
          Object.entries(rules).filter(([, value]) => value !== null),
        )}
        render={({ field: { ref, ...field }, fieldState }) => (
          <DatePicker
            {...field}
            inputRef={ref}
            label={label}
            value={field.value ? parseISO(field.value) : null}
            onChange={(newValue) =>
              field.onChange(
                newValue
                  ? formatISO(newValue, { representation: 'date' })
                  : null,
              )
            }
            openTo="year"
            views={['year', 'month', 'day']}
            // minDate={minDate}
            // maxDate={maxDate}
            disabled={disabled}
            slotProps={{
              textField: {
                error: !!fieldState.error,
                helperText: fieldState.error?.message,
                fullWidth: true,
                InputLabelProps: {
                  required: rules.required?.value,
                },
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
