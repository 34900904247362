import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import {
  ApplicationDocumentFragment,
  UserRole,
} from '../../../../../generated/graphql';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale/ru';
import { ApplicationDocumentSigningDialog } from '../SigningDialog';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const ApplicationDocumentCard = ({
  applicationDocument,
  officerIIN,
}: {
  applicationDocument: ApplicationDocumentFragment;
  officerIIN: string;
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={1}>
          <Box>
            <Grid container>
              <Grid item xs>
                <Typography fontWeight="500">
                  {applicationDocument.serviceDocument.name}
                </Typography>
              </Grid>
              {applicationDocument.referenceNumber && (
                <Grid item xs="auto">
                  <Typography color="gray">
                    {applicationDocument.referenceNumber}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>

          <Typography>
            {applicationDocument.application.student.user.fullName}
          </Typography>

          <Typography variant="body2" color="gray">
            {applicationDocument.id}
          </Typography>

          {applicationDocument.signers.length > 0 && (
            <Box>
              <Typography>Подписи</Typography>
              {applicationDocument.signers.map(({ user, signed }) => (
                <Typography key={user.id}>
                  {`${
                    user.role === UserRole.Student
                      ? 'Студент'
                      : `${user.officer?.position.name} (${user.lastName} ${user.firstName[0]} ${user.middleName[0]})`
                  }: ${signed ? 'Подписано' : 'В ожидании'}`}
                </Typography>
              ))}
            </Box>
          )}

          {applicationDocument.fileCardUrl && (
            <Link
              component={RouterLink}
              to={applicationDocument.fileCardUrl}
              target="_blank"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography
                noWrap
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                Карточка электронного документа{' '}
              </Typography>
              <OpenInNewIcon sx={{ ml: '6px', fontSize: '16px' }} />
            </Link>
          )}

          <Link
            component={RouterLink}
            to={applicationDocument.signedURL}
            target="_blank"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography
              noWrap
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              Документ
            </Typography>
            <OpenInNewIcon sx={{ ml: '6px', fontSize: '16px' }} />
          </Link>

          <Typography variant="body2" color="gray">
            {formatDistanceToNow(applicationDocument.createdAt, {
              addSuffix: true,
              locale: ru,
            })}
          </Typography>

          {/* {applicationDocument.sigexDocument && (
            <Box>
              <Typography>Подписи</Typography>
              <Typography>
                {applicationDocument.sigexDocument.signatures
                  .map((s) => s.signId)
                  .join(', ')}
              </Typography>
            </Box>
          )} */}
        </Stack>
      </CardContent>
      <CardActions>
        {applicationDocument.sigexDocument?.signatures.find(
          (signature) => signature.user.iin === officerIIN,
        ) ? (
          <Button disabled fullWidth>
            Подписано
          </Button>
        ) : (
          <LoadingButton onClick={handleOpen} variant="outlined" fullWidth>
            Подписать
          </LoadingButton>
        )}
      </CardActions>
      <ApplicationDocumentSigningDialog
        open={open}
        onClose={handleClose}
        applicationDocumentId={applicationDocument.id}
        signedURL={applicationDocument.signedURL}
        serviceDocumentName={applicationDocument.serviceDocument.name}
      />
    </Card>
  );
};
