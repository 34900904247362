import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { dormApplicationFiltersVar } from '../../config/apollo/vars';
import { useReactiveVar } from '@apollo/client';
import { DormApplicationFilter } from '../../generated/graphql';

const dormApplicationFilterDict: {
  [key in DormApplicationFilter]: string;
} = {
  [DormApplicationFilter.Orphan]: 'Сирота',
  [DormApplicationFilter.Grant]: 'Грант',
  [DormApplicationFilter.Repatriate]: 'Кандас',
  [DormApplicationFilter.NonResident]: 'Нерезидент',
  [DormApplicationFilter.Disability]: 'Инвалидность',
  [DormApplicationFilter.LargeFamily]: 'Многодетная семья',
  [DormApplicationFilter.Achievements]: 'Достижения',
};

export const DormApplicationFilters = () => {
  const dormApplicationFilters = useReactiveVar(dormApplicationFiltersVar);

  const handleChange = (
    event: SelectChangeEvent<typeof dormApplicationFilters>,
  ) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === '') {
      dormApplicationFiltersVar([]);
      return;
    }

    dormApplicationFiltersVar(value as DormApplicationFilter[]);
  };

  return (
    <FormControl sx={{ width: '300px' }} size="small">
      <InputLabel>Фильтры</InputLabel>
      <Select
        value={dormApplicationFilters}
        onChange={handleChange}
        multiple
        input={<OutlinedInput label="Фильтры" />}
        renderValue={(selected) =>
          selected.map((filter) => dormApplicationFilterDict[filter]).join(', ')
        }
      >
        <MenuItem value="">
          <Checkbox checked={dormApplicationFilters.length === 0} />
          <ListItemText primary="Все" />
        </MenuItem>
        {Object.entries(dormApplicationFilterDict).map(([filter, label]) => (
          <MenuItem key={filter} value={filter}>
            <Checkbox
              checked={dormApplicationFilters.includes(
                filter as DormApplicationFilter,
              )}
            />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
