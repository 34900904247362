import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  VerificationFragment,
  useValidateApplicationFormMutation,
} from '../../generated/graphql';
import { VerificationBar } from '../VerificationBar';

export const FormCardVerificationBar = ({
  applicationId,
  verification,
}: {
  applicationId: string;
  verification?: VerificationFragment | null;
}) => {
  const [validateApplicationForm, { loading }] =
    useValidateApplicationFormMutation();

  const handleVerify = async (errorMessage?: string) => {
    await validateApplicationForm({
      variables: {
        applicationId,
        errorMessage,
      },
      update(cache, result) {
        const newVerification = result.data?.officerValidateApplicationForm;

        if (!newVerification) {
          throw Error('Something went wrong');
        }

        cache.updateQuery<GetApplicationQuery, GetApplicationQueryVariables>(
          {
            query: GetApplicationDocument,
            variables: {
              id: applicationId,
            },
          },
          (data) => {
            if (data !== null) {
              return {
                officerGetApplication: {
                  ...data.officerGetApplication,
                  data: {
                    ...data.officerGetApplication.data,
                    form: {
                      ...data.officerGetApplication.data.form,
                      verification: newVerification,
                    },
                  },
                },
              };
            }
          },
        );
      },
    });
  };

  return (
    <VerificationBar
      verification={verification}
      handleVerify={handleVerify}
      loading={loading}
    />
  );
};
