import {
  Button,
  CardActions,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { VerificationErrorFragment } from '../../generated/graphql';

type VerficationBarErrorFormInputs = {
  errorMessage: string;
};

export const VerficationBarErrorForm = ({
  error,
  handleValidate,
  handleClose,
  loading,
}: {
  error?: VerificationErrorFragment | null;
  handleValidate: (errorMessage?: string) => void;
  handleClose: () => void;
  loading: boolean;
}) => {
  const { control, handleSubmit } = useForm<VerficationBarErrorFormInputs>({
    defaultValues: {
      errorMessage: error?.message || '',
    },
  });

  const onSubmit: SubmitHandler<VerficationBarErrorFormInputs> = (data) => {
    handleValidate(data.errorMessage);
  };

  return (
    <CardActions>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: '100%',
        }}
      >
        <Stack spacing={1}>
          <Controller
            name="errorMessage"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Обязательное поле',
              },
              minLength: {
                value: 10,
                message: 'Минимум 10 символов',
              },
            }}
            disabled={loading}
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState.error} fullWidth>
                <TextField
                  {...field}
                  label="Описание ошибки"
                  multiline
                  autoFocus
                  required
                  minRows={2}
                  maxRows={8}
                  error={!!fieldState.error}
                />
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleClose}
                disabled={loading}
              >
                Назад
              </Button>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
              >
                Сохранить
              </LoadingButton>
            </Stack>

            {error?.message && !error.corrected && (
              <LoadingButton
                fullWidth
                onClick={() => handleValidate()}
                loading={loading}
                color="error"
                variant="outlined"
              >
                Ошибок нет!
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      </form>
    </CardActions>
  );
};
