import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Chip,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import {
  FormSnapshotFragment,
  InputFragment,
  InputType,
} from '../../generated/graphql';
import { VerificationStatus } from '../VerificationStatus/VerificationStatus';

import { FormCardVerificationBar } from './FormCardVerificationBar';
import { formatInputValue } from './utils';

export const FormCard = ({
  form,
  inputs,
  review,
}: {
  form: FormSnapshotFragment;
  inputs: InputFragment[];
  review?: {
    applicationId: string;
  };
}) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={1}>
          <Box display="flex" alignItems="center">
            <Typography fontWeight="500">Данные заявления</Typography>
            <VerificationStatus verification={form.verification} />
          </Box>
          {form.verification?.error && (
            <Alert
              severity={form.verification.error.corrected ? 'warning' : 'error'}
            >
              <AlertTitle>
                {form.verification.error.corrected
                  ? 'Обнаруженные ранее ошибки'
                  : 'Обнаружены ошибки'}
              </AlertTitle>
              {form.verification.error.message}
            </Alert>
          )}
          {inputs
            .filter((input) => form.data[input.name] !== undefined)
            .map((input) => {
              if (input.type === InputType.Checkbox) {
                if (form.data[input.name] === false) {
                  return null;
                }

                return (
                  <Chip
                    key={input.name}
                    label={input.label}
                    variant="outlined"
                  />
                );
              }

              return (
                <Box key={input.name}>
                  <FormLabel component="legend">{input.label}</FormLabel>
                  <Typography>
                    {formatInputValue(
                      form.data[input.name],
                      input.type,
                      input.params.options,
                    )}
                  </Typography>
                </Box>
              );
            })}
        </Stack>
      </CardContent>
      {review && (
        <FormCardVerificationBar
          applicationId={review.applicationId}
          verification={form.verification}
        />
      )}
    </Card>
  );
};
