import { useGetApplicationsQuery } from '../../../generated/graphql';
import {
  DataGrid,
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { ruRU } from '@mui/x-data-grid/locales';
import { useCallback, useMemo, useRef, useState } from 'react';
import { APPLICATIONS_TABLE_COLUMNS } from './constants';
import { useReactiveVar } from '@apollo/client';
import {
  degreeFilterVar,
  dormApplicationFiltersVar,
} from '../../../config/apollo/vars';

export const ApplicationOverviewView = () => {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const dormApplicationFilters = useReactiveVar(dormApplicationFiltersVar);
  const degreeFilter = useReactiveVar(degreeFilterVar);

  const [queryOptions, setQueryOptions] = useState<{
    filter: Omit<GridFilterItem, 'id'> | undefined;
    sortModel: GridSortModel;
  }>({
    filter: undefined,
    sortModel: [
      {
        field: 'createdAt',
        sort: 'desc',
      },
    ],
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const { loading, data } = useGetApplicationsQuery({
    variables: {
      ...paginationModel,
      sortModel: queryOptions.sortModel,
      filter: queryOptions.filter?.value ? queryOptions.filter : undefined,
      degreeFilter,
      dormApplicationFilters:
        dormApplicationFilters.length === 0
          ? undefined
          : dormApplicationFilters,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (columns.length === 0) {
        setColumns(() =>
          APPLICATIONS_TABLE_COLUMNS.map((col) => {
            if (col.field === 'serviceName') {
              return {
                ...col,
                valueOptions: data.officerGetApplications.serviceOptions
                  .map((serviceOption) => ({
                    value: serviceOption.id,
                    label: serviceOption.name,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label)),
              };
            } else if (col.field === 'stageName') {
              return {
                ...col,
                valueOptions: data.officerGetApplications.stageOptions
                  .reduce<
                    {
                      label: string;
                      value: string[];
                    }[]
                  >((acc, cur) => {
                    const existing = acc.find(
                      (item) => item.label === cur.name,
                    );

                    if (existing) {
                      existing.value.push(cur.id);
                    } else {
                      acc.push({ label: cur.name, value: [cur.id] });
                    }

                    return acc;
                  }, [])
                  .sort((a, b) => a.label.localeCompare(b.label)),
              };
            }

            return col;
          }),
        );
      }
    },
  });

  const rowCountRef = useRef(data?.officerGetApplications.rowCount || 0);

  const rowCount = useMemo(() => {
    if (data?.officerGetApplications.rowCount !== undefined) {
      rowCountRef.current = data.officerGetApplications.rowCount;
    }
    return rowCountRef.current;
  }, [data?.officerGetApplications.rowCount]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setQueryOptions((prevQueryOptions) => ({
      ...prevQueryOptions,
      filter:
        filterModel.items.length !== 1 ||
        (filterModel.items[0].operator === 'isAnyOf' &&
          Array.isArray(filterModel.items[0].value) &&
          filterModel.items[0].value.length === 0)
          ? undefined
          : {
              field: filterModel.items[0].field,
              operator: filterModel.items[0].operator,
              value: filterModel.items[0].value,
            },
    }));
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setQueryOptions((prevQueryOptions) => ({
      ...prevQueryOptions,
      sortModel: [...sortModel],
    }));
  }, []);

  return (
    <Box
      height={{
        xs: 'calc(100vh - 56px - 57px)',
        sm: 'calc(100vh - 64px - 57px)',
      }}
      width="100%"
    >
      <DataGrid
        rows={data?.officerGetApplications.rows}
        columns={columns.length === 0 ? APPLICATIONS_TABLE_COLUMNS : columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
        rowCount={rowCount}
        loading={loading}
        pageSizeOptions={[10, 15, 25, 50]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        disableRowSelectionOnClick
        sx={{ '&, [class^=MuiDataGrid-root]': { border: 'none' } }}
        disableColumnSelector
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
  );
};
