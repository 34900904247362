import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QrAuthentication } from '../../../../../shared';

export const SignInQr = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        fontSize={{
          xs: 20,
          md: 36,
        }}
        textAlign="center"
        gutterBottom
      >
        {t(`sign-in.Qr.title.${isMobile ? 'mobile' : 'desktop'}`)}
      </Typography>
      <QrAuthentication />
    </Box>
  );
};
