import {
  QrFragment,
  useGetQrQuery,
  useLoadQrQuery,
  useQrAuthenticateQuery,
} from '../../generated/graphql';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { QrCode } from './QrCode';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const ViewMobile = ({ qr, signing }: { qr: QrFragment; signing: boolean }) => {
  const { t } = useTranslation();
  const handleClick = () => {
    window.location.href = qr.eGovMobileLaunchLink;
  };

  return (
    <LoadingButton
      onClick={handleClick}
      fullWidth
      size="large"
      loading={signing}
      loadingIndicator="На подписании…"
      variant={signing ? 'outlined' : 'contained'}
    >
      {t('sign-in.buttons.eGov')}
    </LoadingButton>
  );
};

const ViewDesktop = ({ qr, signing }: { qr: QrFragment; signing: boolean }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <QrCode qrCode={qr.qrCode} signing={signing} />
    </Box>
  );
};

const ViewController = ({
  qr,
  signing,
}: {
  qr: QrFragment;
  signing: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return <ViewMobile qr={qr} signing={signing} />;
  }

  return <ViewDesktop qr={qr} signing={signing} />;
};

const GetSignQr = ({ qr, nonce }: { qr: QrFragment; nonce?: string }) => {
  const { loading: signing, error } = useQrAuthenticateQuery({
    variables: {
      signURL: qr.signURL,
      nonce: nonce!,
    },
    skip: !nonce,
  });

  if (error) return `Error! ${error.message}`;

  return <ViewController qr={qr} signing={signing} />;
};

const LoadQr = ({ qr }: { qr: QrFragment }) => {
  const { data, error } = useLoadQrQuery({
    variables: {
      dataURL: qr.dataURL,
    },
  });

  if (error) return `Error! ${error.message}`;

  return <GetSignQr qr={qr} nonce={data?.loadQr} />;
};

const GetQrLoading = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <LoadingButton
        loading
        loadingIndicator="Подготовка…"
        variant="outlined"
        fullWidth
        size="large"
      >
        Placeholder
      </LoadingButton>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export const GetQr = () => {
  const { loading, error, data } = useGetQrQuery({
    fetchPolicy: 'network-only',
    pollInterval: 60 * 1000,
  });

  if (loading) return <GetQrLoading />;
  if (error) return `Error! ${JSON.stringify(error)}`;
  if (!data) return 'Smth went wrong';

  return <LoadQr qr={data.getQr} />;
};
