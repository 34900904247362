import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { degreeFilterVar } from '../../config/apollo/vars';
import { useReactiveVar } from '@apollo/client';
import { Degree } from '../../generated/graphql';

const degreeFilterDict: {
  [key in Degree]: string;
} = {
  [Degree.Bachelor]: 'Бакалавриат',
  [Degree.Master]: 'Магистратура',
  [Degree.Doctorate]: 'Докторантура',
};

export const DegreeFilter = () => {
  const degreeFilter = useReactiveVar(degreeFilterVar);

  const handleChange = (event: SelectChangeEvent) => {
    degreeFilterVar(
      event.target.value === '' ? undefined : (event.target.value as Degree),
    );
  };

  return (
    <FormControl sx={{ minWidth: '200px' }} size="small">
      <InputLabel>Программа</InputLabel>
      <Select
        value={degreeFilter || ''}
        onChange={handleChange}
        label="Программа"
      >
        <MenuItem value="">Все</MenuItem>
        {Object.entries(degreeFilterDict).map(([filter, label]) => (
          <MenuItem key={filter} value={filter}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
