import { CardActions } from '@mui/material';
import { VerificationFragment } from '../../generated/graphql';

import { LoadingButton } from '@mui/lab';

export const VerficationBar = ({
  verification,
  loading,
  handleValidate,
}: {
  verification?: VerificationFragment | null;
  loading: boolean;
  handleValidate: (errorMessage?: string) => void;
}) => {
  if (!verification) {
    return (
      <CardActions>
        <LoadingButton
          fullWidth
          onClick={() => handleValidate()}
          loading={loading}
          variant="contained"
        >
          Подтвердить
        </LoadingButton>
      </CardActions>
    );
  }

  if (verification.error?.corrected) {
    return (
      <CardActions>
        <LoadingButton
          fullWidth
          onClick={() => handleValidate()}
          loading={loading}
          variant="contained"
        >
          Ошибки были исправлены
        </LoadingButton>
      </CardActions>
    );
  }

  return null;
};
