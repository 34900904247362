import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from '@mui/material';
import {
  GetApplicationDocumentIdsForBulkSigningDocument,
  GetApplicationDocumentIdsForBulkSigningQuery,
  GetApplicationDocumentIdsForBulkSigningQueryVariables,
  useGetApplicationDocumentsForBulkSigningStreamSubscription,
} from '../../../../../generated/graphql';
import { useMemo, useState } from 'react';
import { QrMultipleSigning } from '../../../../../common/QrSigning/QrMultipleSigning';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useApolloClient } from '@apollo/client';

export const BulkSigningDialog = ({ onClose }: { onClose: () => void }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [page, setPage] = useState(1);
  const client = useApolloClient();

  const applicationDocumentForBulkSigning = useMemo(() => {
    return client.readQuery<
      GetApplicationDocumentIdsForBulkSigningQuery,
      GetApplicationDocumentIdsForBulkSigningQueryVariables
    >({
      query: GetApplicationDocumentIdsForBulkSigningDocument,
    });
  }, []);

  const { data, error, loading } =
    useGetApplicationDocumentsForBulkSigningStreamSubscription({
      variables: {
        applicationDocumentIds:
          applicationDocumentForBulkSigning!
            .officerGetApplicationDocumentIdsForBulkSigning,
      },
      skip: applicationDocumentForBulkSigning === null,
    });

  const applicationDocumentCount = useMemo<number>(
    () =>
      applicationDocumentForBulkSigning
        ?.officerGetApplicationDocumentIdsForBulkSigning.length ?? -1,
    [
      applicationDocumentForBulkSigning
        ?.officerGetApplicationDocumentIdsForBulkSigning.length,
    ],
  );

  if (error) {
    throw new Error('Subscription error');
  }

  const handleClose = () => {
    onClose();
  };

  const renderContent = () => {
    if (!data || loading) {
      return <CircularProgress />;
    }

    if (applicationDocumentCount === 0) {
      return <Typography>Документы на подписание отсутствуют</Typography>;
    }

    if (isMobile) {
      return (
        <Stack spacing={1}>
          {data.officerGetApplicationDocumentsForBulkSigningStream
            .slice((page - 1) * 50, page * 50)
            .map((applicationDocument) => (
              <Box key={applicationDocument.id}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Link
                      component={RouterLink}
                      to={applicationDocument.signedURL}
                      target="_blank"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {applicationDocument.serviceDocument.name}
                        {' – '}
                        {applicationDocument.application.student.user.initials}
                      </Typography>
                      <OpenInNewIcon sx={{ ml: '6px', fontSize: '16px' }} />
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {applicationDocument.signers.find((signer) => signer.isMe)
                        ?.signed
                        ? 'Подписано'
                        : 'Не подписано'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
        </Stack>
      );
    }

    return (
      <Grid container spacing={2}>
        <Grid
          item
          sm={12}
          md={6}
          lg={8}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Stack
            spacing={1}
            sx={{
              maxHeight: '400px',
              overflowY: 'auto',
            }}
          >
            {data.officerGetApplicationDocumentsForBulkSigningStream
              .slice((page - 1) * 50, page * 50)
              .map((applicationDocument) => (
                <Box key={applicationDocument.id}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                      <Typography>
                        {applicationDocument.application.student.user.fullName}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} lg={4}>
                      <Link
                        component={RouterLink}
                        to={applicationDocument.signedURL}
                        target="_blank"
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        {applicationDocument.serviceDocument.name}
                        <OpenInNewIcon sx={{ ml: '6px', fontSize: '16px' }} />
                      </Link>
                    </Grid>
                    <Grid item xs={4} lg={2}>
                      <Typography>
                        {applicationDocument.signers.find(
                          (signer) => signer.isMe,
                        )?.signed
                          ? 'Подписано'
                          : 'Не подписано'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </Stack>

          {data.officerGetApplicationDocumentsForBulkSigningStream.length >
            50 && (
            <Pagination
              count={Math.ceil(applicationDocumentCount / 50)}
              color="primary"
              page={page}
              hideNextButton
              hidePrevButton
              disabled
              sx={{
                alignSelf: 'center',
              }}
            />
          )}
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          {data.officerGetApplicationDocumentsForBulkSigningStream
            .slice((page - 1) * 50, page * 50)
            .every(
              (a) => a.signers.find((userSigning) => userSigning.isMe)?.signed,
            ) ? (
            data.officerGetApplicationDocumentsForBulkSigningStream.every(
              (a) => a.signers.find((userSigning) => userSigning.isMe)?.signed,
            ) ? (
              <Typography>Все документы были подписаны</Typography>
            ) : (
              <Stack spacing={2} direction="column" alignItems="center">
                <Typography>50 документов были подписаны</Typography>
                <Link
                  component="button"
                  onClick={() => setPage((prevPage) => prevPage + 1)}
                >
                  Следующая страница
                </Link>
              </Stack>
            )
          ) : (
            <Box>
              <Typography textAlign="center">
                Подписать с eGov Business
              </Typography>

              <QrMultipleSigning
                applicationDocuments={data.officerGetApplicationDocumentsForBulkSigningStream
                  .slice((page - 1) * 50, page * 50)
                  .map((applicationDocument, index) => ({
                    id: applicationDocument.id,
                    index,
                  }))}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderMobileButton = () => {
    if (!data || loading) {
      return null;
    }

    if (applicationDocumentCount === 0) {
      return null;
    }

    const applicationDocumentsToSign =
      data.officerGetApplicationDocumentsForBulkSigningStream.slice(
        (page - 1) * 50,
        page * 50,
      );

    if (
      applicationDocumentsToSign.every(
        (a) => a.signers.find((userSigning) => userSigning.isMe)?.signed,
      )
    ) {
      return (
        <Button
          fullWidth
          variant="contained"
          onClick={() => setPage((prevPage) => prevPage + 1)}
        >
          Далее
        </Button>
      );
    }

    if (
      data.officerGetApplicationDocumentsForBulkSigningStream.every(
        (ad) => ad.signers.find((s) => s.isMe)?.signed,
      )
    ) {
      <Button fullWidth variant="text">
        Все документы были подписаны
      </Button>;
    }

    return (
      <QrMultipleSigning
        applicationDocuments={applicationDocumentsToSign.map(
          (applicationDocument, index) => ({
            id: applicationDocument.id,
            index,
          }),
        )}
      />
    );
    // (
    //   <LoadingButton
    //     fullWidth
    //     variant="contained"
    //     onClick={handleBulkSign}
    //     loading={signLoading}
    //     loadingIndicator="Подписание..."
    //   >
    //     Подписать {applicationDocumentsToSign.length} документов
    //   </LoadingButton>
    // );
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      fullScreen={isMobile}
      fullWidth
      maxWidth="lg"
      scroll="paper"
    >
      <DialogTitle borderBottom="1px solid rgba(0, 0, 0, 0.12)">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">
            Массовое подписание
            {data &&
              ` (${
                data.officerGetApplicationDocumentsForBulkSigningStream.filter(
                  (applicationDocument) =>
                    applicationDocument.signers.find((signer) => signer.isMe)
                      ?.signed,
                ).length
              }/${applicationDocumentCount})`}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Box pt={2}>{renderContent()}</Box>
      </DialogContent>
      {isMobile && (
        <DialogActions>
          <Stack
            spacing={1}
            direction={isMobile ? 'column-reverse' : 'row'}
            width={isMobile ? '100%' : 'auto'}
          >
            <Button fullWidth variant="outlined" onClick={handleClose}>
              Закрыть
            </Button>
            {renderMobileButton()}
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
};
