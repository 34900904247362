import {
  StageActionFragment,
  StageActionType,
  StageTransitionFragment,
} from '../../../../generated/graphql';
import {
  StageActionButtonReview,
  StageActionButtonSigning,
  StageActionButtonDormDistribution,
} from './components';

export const StageActionButton = ({
  applicationId,
  transitions,
  action,
}: {
  applicationId: string;
  transitions: StageTransitionFragment[];
  action: StageActionFragment;
}) => {
  const renderButton = () => {
    switch (action.type) {
      case StageActionType.Review:
        return (
          <StageActionButtonReview
            applicationId={applicationId}
            transitions={transitions}
            button={action.button}
          />
        );
      case StageActionType.Signing:
        return (
          <StageActionButtonSigning
            applicationId={applicationId}
            button={action.button}
          />
        );
      case StageActionType.DormDistribution:
        return (
          <StageActionButtonDormDistribution
            applicationId={applicationId}
            transitions={transitions}
            button={action.button}
          />
        );
      default:
        return null;
    }
  };

  return renderButton();
};
