import { Box, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { ApplicationDocumentOverviewQuery } from './ApplicationDocumentOverviewQuery';
import { useState } from 'react';
import { ApplicationDocumentSigningState } from '../../../generated/graphql';
import {
  ApplicationDocumentLayoutToggle,
  ApplicationDocumentSigningStateToggle,
} from './components';
import { ApplicationDocumentLayoutType } from './components/LayoutToggle';
import { applicationDocumentSigningStateDict } from './utils';
import { APPLICATION_DOCUMENT_SIGNING_STATES } from './constants';

export const ApplicationDocumentOverviewContainer = () => {
  const [signingState, setSigningState] =
    useState<ApplicationDocumentSigningState>(
      ApplicationDocumentSigningState.Signing,
    );
  const [layout, setLayout] = useState<ApplicationDocumentLayoutType>('TABLE');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSigningState(APPLICATION_DOCUMENT_SIGNING_STATES[newValue]);
  };

  return (
    <>
      {isMobile ? (
        <Box
          sx={(theme) => ({
            position: 'sticky',
            top: 56 + 57,
            zIndex: theme.zIndex.appBar,
          })}
        >
          <Tabs
            value={APPLICATION_DOCUMENT_SIGNING_STATES.findIndex(
              (applicationDocumentSigningState) =>
                applicationDocumentSigningState === signingState,
            )}
            onChange={handleChange}
            variant="fullWidth"
            sx={{
              bgcolor: '#ffffff',
              mb: 2,
            }}
          >
            {APPLICATION_DOCUMENT_SIGNING_STATES.map(
              (applicationDocumentSigningState) => (
                <Tab
                  key={applicationDocumentSigningState}
                  label={
                    applicationDocumentSigningStateDict[
                      applicationDocumentSigningState
                    ]
                  }
                />
              ),
            )}
          </Tabs>
        </Box>
      ) : (
        <Box
          p={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={(theme) => ({
            position: 'sticky',
            top: 64 + 57,
            zIndex: theme.zIndex.appBar,
            bgcolor: '#ffffff',
          })}
        >
          <ApplicationDocumentSigningStateToggle
            state={signingState}
            setState={setSigningState}
          />
          <ApplicationDocumentLayoutToggle
            layout={layout}
            setLayout={setLayout}
          />
        </Box>
      )}

      <Box p={2} pt={0}>
        <ApplicationDocumentOverviewQuery
          state={signingState}
          layout={layout}
        />
      </Box>
    </>
  );
};
