import { VerificationFragment } from '../../generated/graphql';
import { useState } from 'react';
import { VerficationBarErrorForm } from './VerificationBarErrorForm';
import { VerficationBar } from './VerificationBar';

export const VerificationBarController = ({
  verification,
  handleVerify,
  loading,
}: {
  verification?: VerificationFragment | null;
  handleVerify: (errorMessage?: string) => Promise<void>;
  loading: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValidate = async (errorMessage?: string) => {
    await handleVerify(errorMessage);
    handleClose();
  };

  if (open) {
    return (
      <VerficationBarErrorForm
        error={verification?.error}
        loading={loading}
        handleValidate={handleValidate}
        handleClose={handleClose}
      />
    );
  }

  return (
    <VerficationBar
      verification={verification}
      loading={loading}
      handleValidate={handleValidate}
      handleOpen={handleOpen}
    />
  );
};
