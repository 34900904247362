import { Button, CardActions, Stack } from '@mui/material';
import { VerificationFragment } from '../../generated/graphql';

import { LoadingButton } from '@mui/lab';

export const VerficationBar = ({
  verification,
  loading,
  handleOpen,
  handleValidate,
}: {
  verification?: VerificationFragment | null;
  loading: boolean;
  handleOpen: () => void;
  handleValidate: (errorMessage?: string) => void;
}) => {
  if (!verification) {
    return (
      <CardActions>
        <Stack spacing={1} width="100%">
          <LoadingButton
            fullWidth
            onClick={() => handleValidate()}
            loading={loading}
            variant="contained"
          >
            Подтвердить
          </LoadingButton>
          <Button
            fullWidth
            color="error"
            onClick={handleOpen}
            variant="outlined"
          >
            Обнаружены ошибки
          </Button>
        </Stack>
      </CardActions>
    );
  }

  if (!verification.error) {
    return (
      <CardActions>
        <Stack spacing={1} width="100%">
          <Button
            fullWidth
            color="error"
            onClick={handleOpen}
            variant="outlined"
          >
            Обнаружены ошибки
          </Button>
        </Stack>
      </CardActions>
    );
  }

  return verification.error.corrected ? (
    <CardActions>
      <Stack spacing={1} width="100%">
        <LoadingButton
          fullWidth
          onClick={() => handleValidate()}
          loading={loading}
          variant="contained"
        >
          Ошибки были исправлены
        </LoadingButton>
        <Button fullWidth color="error" onClick={handleOpen} variant="outlined">
          Дополнить сообщение об ошибках
        </Button>
      </Stack>
    </CardActions>
  ) : (
    <CardActions>
      <Stack spacing={1} width="100%">
        <Button
          fullWidth
          variant={!verification?.error ? 'text' : 'outlined'}
          color="error"
          onClick={handleOpen}
        >
          Дополнить сообщение об ошибках
        </Button>
      </Stack>
    </CardActions>
  );
};
