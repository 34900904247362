import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  ApplicationDocumentFragment,
  ApplicationDocumentSigningState,
  useGetCurrentUserQuery,
} from '../../../generated/graphql';
import { ApplicationDocumentLayoutType } from './components/LayoutToggle';
import { ApplicationDocumentOverviewCardView } from './ApplicationDocumentOverviewCardView';
import { ApplicationDocumentOverviewTableView } from './ApplicationDocumentOverviewTableView';

export const ApplicationDocumentOverviewController = ({
  applicationDocuments,
  layout,
  state,
}: {
  applicationDocuments: ApplicationDocumentFragment[];
  layout: ApplicationDocumentLayoutType;
  state: ApplicationDocumentSigningState;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data } = useGetCurrentUserQuery({ fetchPolicy: 'cache-only' });

  if (!data) {
    throw new Error('Current user is missing in cache');
  }

  return (
    <Box>
      {layout === 'TABLE' && !isMobile ? (
        <ApplicationDocumentOverviewTableView
          applicationDocuments={applicationDocuments}
          officerIIN={data.officerGetCurrentUser.iin}
          state={state}
        />
      ) : (
        <ApplicationDocumentOverviewCardView
          applicationDocuments={applicationDocuments}
          officerIIN={data.officerGetCurrentUser.iin}
        />
      )}
    </Box>
  );
};
