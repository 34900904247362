/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputOptionFragment, InputType } from '../../generated/graphql';

const { Radio, Text, University, Faculty, Program, Speciality } = InputType;

export const formatInputValue = (
  value: any,
  inputType: InputType,
  options?: InputOptionFragment[] | null,
): string => {
  let result = '';

  switch (inputType) {
    case University:
    case Program:
    case Faculty:
    case Speciality:
      result = value.name;
      break;
    case Radio:
      result =
        options?.find((option) => value === option.value)?.label ?? 'ОШИБКА';
      break;
    case Text:
      result = value;
      break;
    default:
      result = value;
      break;
  }

  return result;
};
