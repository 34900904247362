import { Box, Button, FormLabel, Grid, Typography } from '@mui/material';
import { InputFragment } from '../../generated/graphql';
import { formatInputValue } from './utils';
import { useState } from 'react';
import { OfficerFormEdit } from './OfficerFormEdit';

export const OfficerFormCardShow = ({
  applicationId,
  data,
  formData,
  inputs,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
  applicationId: string;
  inputs: InputFragment[];
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return open ? (
    <OfficerFormEdit
      data={data}
      formData={formData}
      applicationId={applicationId}
      inputs={inputs}
      handleClose={handleClose}
    />
  ) : (
    <Box>
      <Grid container spacing={2}>
        {inputs
          .filter((input) => data[input.name] !== undefined)
          .map((input) => (
            <Grid item xs={12} key={input.name}>
              <FormLabel component="legend">{input.label}</FormLabel>
              <Typography>
                {formatInputValue(data[input.name], input.type)}
              </Typography>
            </Grid>
          ))}
        <Grid item xs={12}>
          <Button variant="outlined" fullWidth onClick={handleOpen}>
            Изменить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
