import {
  ApolloLink,
  Operation,
  FetchResult,
  Observable,
  split,
  HttpLink,
} from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { print } from 'graphql';
import { createClient, ClientOptions, Client } from 'graphql-sse';

class SSELink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      );
    });
  }
}

const sseLink = new SSELink({
  url: `${import.meta.env.VITE_GRAPHQL_SERVER}/stream`,
  credentials: 'include',
});

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_SERVER,
  credentials: 'include',
});

export const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  sseLink,
  httpLink,
);
