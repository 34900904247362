import { formatDistanceToNow, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale/ru';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import {
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';
import { ApplicationRowFragment } from '../../../../generated/graphql';
import { Link } from '@mui/material';

export const APPLICATIONS_TABLE_COLUMNS: GridColDef[] = [
  {
    field: 'iin',
    headerName: 'ИИН',
    width: 150,
    sortable: false,
    type: 'string',
    filterOperators: getGridStringOperators().filter((operator) =>
      ['contains', 'equals', 'startsWith', 'endsWith'].includes(operator.value),
    ),
  },
  {
    field: 'fullName',
    headerName: 'ФИО',
    width: 350,
    sortable: false,
    type: 'string',
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'contains',
    ),
  },
  {
    field: 'serviceName',
    headerName: 'Услуга',
    type: 'singleSelect',
    valueOptions: [],
    width: 250,
    sortable: false,
    renderCell: (
      params: GridRenderCellParams<ApplicationRowFragment, string>,
    ) => (
      <Link
        component={RouterLink}
        to={`/applications/${params.row.id}`}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        target="_blank"
      >
        {params.value}
        <OpenInNewIcon sx={{ ml: '6px', fontSize: '16px' }} />
      </Link>
    ),
  },
  {
    field: 'stageName',
    headerName: 'Статус',
    type: 'singleSelect',
    sortable: false,
    valueOptions: [],
    width: 250,
    renderCell: (
      params: GridRenderCellParams<ApplicationRowFragment, string>,
    ) => {
      return <span>{params.value}</span>;
    },
  },
  {
    field: 'createdAt',
    headerName: 'Создано',
    type: 'dateTime',
    valueGetter: (value) => parseISO(value),
    valueFormatter: (value) =>
      formatDistanceToNow(value, {
        addSuffix: true,
        locale: ru,
      }),
    width: 200,
    filterable: false,
  },
  {
    field: 'updatedAt',
    headerName: 'Обновлено',
    type: 'dateTime',
    valueGetter: (value) => parseISO(value),
    valueFormatter: (value) =>
      formatDistanceToNow(value, {
        addSuffix: true,
        locale: ru,
      }),
    width: 200,
    filterable: false,
  },
];
