export const iinToDate = (iin: string): Date => {
  const yearStr = iin.slice(0, 2);
  const monthStr = iin.slice(2, 4);
  const dayStr = iin.slice(4, 6);

  const fullYearStr =
    parseInt(yearStr) > (new Date().getFullYear() % 2000) - 8
      ? `19${yearStr}`
      : `20${yearStr}`;

  return new Date(`${fullYearStr}-${monthStr}-${dayStr}`);
};
