import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  Stack,
  Box,
  CircularProgress,
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
  IconButton,
} from '@mui/material';
import {
  StageTransitionFragment,
  useGetApplicationQuery,
  useGetDormsQuery,
} from '../../../../../../generated/graphql';
import { StageTransitionButton } from '../../../StageTransitionButton';
import { FormCard } from '../../../../../FormCard';
import { UserCard } from '../../../../../UserCard';
import { StudentCard } from '../../../../../StudentCard';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export const StageActionButtonDormDistributionDialog = ({
  open,
  handleClose,
  applicationId,
  transitions,
}: {
  open: boolean;
  handleClose: () => void;
  applicationId: string;
  transitions: StageTransitionFragment[];
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [dormId, setDormId] = useState<string | undefined>(undefined);

  const { data: dormsData, loading, error } = useGetDormsQuery();

  const { data } = useGetApplicationQuery({
    variables: {
      id: applicationId,
    },
  });

  const renderTransitionButtons = () => {
    return transitions
      .slice()
      .sort((a, b) => a.action!.position - b.action!.position)
      .map((transition) => (
        <StageTransitionButton
          key={transition.nextStageId}
          applicationId={applicationId}
          transition={transition}
          disabled={transition.action!.position === 1 && !dormId}
          dormId={dormId}
        />
      ));
  };

  if (error) {
    return <div>Error</div>;
  }

  if (!data) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
      fullScreen={isMobile}
    >
      <DialogTitle borderBottom="1px solid rgba(0, 0, 0, 0.12)">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Распределение в общежитие</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: '#f3f3f3', p: 2 }}>
        <Box pt={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <UserCard user={data.officerGetApplication.data.user} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <StudentCard
                  student={data.officerGetApplication.data.student}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormCard
                  form={data.officerGetApplication.data.form}
                  inputs={data.officerGetApplication.service.inputs}
                />
              </Grid>
              {dormsData?.officerGetDorms.map((dorm) => {
                const reservedCount =
                  dorm.distribution.RESERVED.MALE +
                  dorm.distribution.RESERVED.FEMALE;

                const checkedInCount =
                  dorm.distribution.CHECKED_IN.MALE +
                  dorm.distribution.CHECKED_IN.FEMALE;

                const availableCount =
                  dorm.data.capacity - reservedCount - checkedInCount;

                const handleDormCardClick = () => {
                  setDormId(dorm.id);
                };

                return (
                  <Grid item key={dorm.id} xs={12} md={6} lg={4}>
                    <Card
                      variant="outlined"
                      sx={(theme) => ({
                        ...(dorm.id === dormId && {
                          borderColor: theme.palette.primary.main,
                          outline: `1px solid ${theme.palette.primary.main}`,
                          bgcolor: '#e3f2fd',
                        }),
                        ...(availableCount === 0 && {
                          bgcolor: theme.palette.action.disabledBackground,
                        }),
                      })}
                    >
                      <CardActionArea
                        onClick={handleDormCardClick}
                        disabled={availableCount === 0}
                      >
                        <CardHeader
                          title={dorm.data.name}
                          subheader={dorm.data.address}
                        />
                        <CardContent sx={{ pt: 0 }}>
                          <ul
                            style={{
                              margin: 0,
                              listStyle: 'none',
                              paddingLeft: 0,
                            }}
                          >
                            <li>
                              <Typography>
                                Всего: {dorm.data.capacity}
                              </Typography>
                            </li>
                            <li>
                              <Typography>
                                Свободно: {availableCount}
                              </Typography>
                            </li>
                            <li>
                              <Typography>
                                Забронировано: {reservedCount}
                              </Typography>
                              <ul
                                style={{
                                  listStyle: 'none',
                                  paddingLeft: '24px',
                                }}
                              >
                                <li>
                                  <Typography>
                                    М: {dorm.distribution.RESERVED.MALE}
                                  </Typography>
                                </li>
                                <li>
                                  <Typography>
                                    Ж: {dorm.distribution.RESERVED.FEMALE}
                                  </Typography>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Typography>
                                Проживают: {checkedInCount}
                              </Typography>
                              <ul
                                style={{
                                  listStyle: 'none',
                                  paddingLeft: '24px',
                                }}
                              >
                                <li>
                                  <Typography>
                                    М: {dorm.distribution.CHECKED_IN.MALE}
                                  </Typography>
                                </li>
                                <li>
                                  <Typography>
                                    Ж: {dorm.distribution.CHECKED_IN.FEMALE}
                                  </Typography>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack
          direction={isMobile ? 'column-reverse' : 'row'}
          spacing={1}
          width={isMobile ? '100%' : 'auto'}
        >
          {renderTransitionButtons()}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
