import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

import { OfficerAppBar } from './OfficerAppBar';
import { Drawer, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { DrawerContent } from '../Drawer/Drawer';
import { Breadcrumbs } from '../../shared/Breadcrumbs';

const drawerWidth = 240;

export const OfficerLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <OfficerAppBar handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          {...(isMobile
            ? {
                variant: 'temporary',
                open: mobileOpen,
                onTransitionEnd: handleDrawerTransitionEnd,
                onClose: handleDrawerClose,
                ModalProps: {
                  keepMounted: true, // Better open performance on mobile.
                },
                sx: {
                  display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                  },
                },
              }
            : {
                variant: 'permanent',
                sx: {
                  display: { xs: 'none', sm: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                  },
                },
                // open: true,
              })}
        >
          <DrawerContent handleDrawerToggle={handleDrawerToggle} />
        </Drawer>
      </Box>
      <Box
        component="main"
        marginLeft={{ sm: drawerWidth / 8 }}
        width={{ sm: `calc(100% - ${drawerWidth}px)` }}
      >
        <Breadcrumbs />
        <Box flex={1} overflow={{ y: 'auto' }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
