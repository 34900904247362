import { ApolloClient } from '@apollo/client';
import { cache } from './cache';
import { splitLink } from './link';

const client = new ApolloClient({
  cache,
  link: splitLink,
});

export default client;
