import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
  useMediaQuery,
  Stack,
  Typography,
  IconButton,
  Alert,
} from '@mui/material';
import {
  InputType,
  StageTransitionAlertFragment,
} from '../../../../../../generated/graphql';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Input } from '../../../../../Input';
import CloseIcon from '@mui/icons-material/Close';

interface Inputs {
  message: string;
}

export const StageTransitionButtonAlertDialog = ({
  open,
  handleClose,
  handleUpdateApplication,
  loading,
  alert,
}: {
  open: boolean;
  handleClose: () => void;
  handleUpdateApplication: (message: string) => void;
  loading: boolean;
  alert: StageTransitionAlertFragment;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const methods = useForm<Inputs>({
    defaultValues: {
      message: '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (formData) => {
    handleUpdateApplication(formData.message);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <DialogTitle borderBottom="1px solid rgba(0, 0, 0, 0.12)">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{alert.title}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Alert
          severity="error"
          sx={{
            my: 2,
          }}
        >
          {alert.description}
        </Alert>
        <form
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
          id="MESSAGE_FORM"
        >
          <Input
            formData={{}}
            input={{
              type: InputType.Textarea,
              label: 'Причина',
              name: 'message',
              position: 0,
              params: {
                defaultValue: '',
              },
              rules: {
                required: {
                  value: true,
                  message: 'Обязательное поле',
                },
                minLength: {
                  value: 10,
                  message: 'Мининмум 10 символов',
                },
              },
            }}
            {...methods}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Stack
          direction={isMobile ? 'column-reverse' : 'row'}
          spacing={1}
          width={isMobile ? '100%' : 'auto'}
        >
          <Button variant="outlined" onClick={handleClose} fullWidth={isMobile}>
            Отмена
          </Button>
          <LoadingButton
            type="submit"
            form="MESSAGE_FORM"
            loading={loading}
            variant={
              alert.button.variant.toLowerCase() as
                | 'text'
                | 'outlined'
                | 'contained'
            }
            color={
              alert.button.color.toLowerCase() as
                | 'error'
                | 'inherit'
                | 'primary'
                | 'secondary'
                | 'success'
                | 'info'
                | 'warning'
            }
            fullWidth={isMobile}
          >
            {alert.button.label}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
