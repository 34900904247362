import {
  Box,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useLoginMutation } from '../../../../../generated/graphql';
import { LoadingButton } from '@mui/lab';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type LoginInputs = {
  email: string;
  password: string;
};

export const SignInEmail = () => {
  const { t } = useTranslation();
  const [login, { loading }] = useLoginMutation();

  const { control, handleSubmit } = useForm<LoginInputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<LoginInputs> = (data) => {
    login({
      variables: {
        email: data.email,
        password: data.password,
      },
    });
  };

  return (
    <Box>
      <Typography
        fontSize={{
          xs: 20,
          md: 36,
        }}
        textAlign="center"
        gutterBottom
      >
        {t('sign-in.Email.title')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={2} mb={2}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('sign-in.Email.form.common.rules.required'),
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('sign-in.Email.form.email.rules.pattern'),
              },
            }}
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState.error} fullWidth>
                <TextField
                  {...field}
                  label={t('sign-in.Email.form.email.label')}
                  type="email"
                  error={!!fieldState.error}
                />
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('sign-in.Email.form.common.rules.required'),
              },
            }}
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState.error} fullWidth>
                <TextField
                  {...field}
                  label={t('sign-in.Email.form.password.label')}
                  type="password"
                  error={!!fieldState.error}
                />
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Stack>
        <LoadingButton
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          loading={loading}
        >
          {t('sign-in.Email.button')}
        </LoadingButton>
      </form>
    </Box>
  );
};
