import { CircularProgress, Typography } from '@mui/material';
import {
  ApplicationDocumentFragment,
  ApplicationDocumentSigningState,
  GetApplicationDocumentIdsForBulkSigningDocument,
  GetApplicationDocumentIdsForBulkSigningQuery,
  GetApplicationDocumentIdsForBulkSigningQueryVariables,
  GetApplicationDocumentsDocument,
  GetApplicationDocumentsQuery,
  GetApplicationDocumentsQueryVariables,
  useGetApplicationDocumentsStreamSubscription,
} from '../../../generated/graphql';
import { ApplicationDocumentOverviewController } from './ApplicationDocumentOverviewController';
import { ApplicationDocumentLayoutType } from './components/LayoutToggle';
import { useMemo } from 'react';
import { useApolloClient } from '@apollo/client';

export const ApplicationDocumentOverviewQuery = ({
  state,
  layout,
}: {
  state: ApplicationDocumentSigningState;
  layout: ApplicationDocumentLayoutType;
}) => {
  const client = useApolloClient();
  const { data, error } = useGetApplicationDocumentsStreamSubscription({
    variables: {
      state,
    },
    onData: ({ data, client }) => {
      if (data.data) {
        client.writeQuery<
          GetApplicationDocumentsQuery,
          GetApplicationDocumentsQueryVariables
        >({
          query: GetApplicationDocumentsDocument,
          variables: {
            state,
          },
          data: {
            officerGetApplicationDocuments:
              data.data.officerGetApplicationDocuments,
          },
        });

        if (state === ApplicationDocumentSigningState.Signing) {
          client.writeQuery<
            GetApplicationDocumentIdsForBulkSigningQuery,
            GetApplicationDocumentIdsForBulkSigningQueryVariables
          >({
            query: GetApplicationDocumentIdsForBulkSigningDocument,
            data: {
              officerGetApplicationDocumentIdsForBulkSigning:
                data.data.officerGetApplicationDocuments.map(({ id }) => id),
            },
          });
        }
      }
    },
  });

  const applicationDocuments = useMemo<
    ApplicationDocumentFragment[] | undefined
  >(() => {
    const data = client.readQuery<
      GetApplicationDocumentsQuery,
      GetApplicationDocumentsQueryVariables
    >({
      query: GetApplicationDocumentsDocument,
      variables: {
        state,
      },
    });

    return data?.officerGetApplicationDocuments;
  }, [client, state, data]);

  if (applicationDocuments == undefined) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (applicationDocuments.length === 0) {
    return (
      <Typography>
        {state === ApplicationDocumentSigningState.Signed
          ? 'Подписанных документов нет'
          : 'Документы на подписание отсутствуют'}
      </Typography>
    );
  }

  return (
    <ApplicationDocumentOverviewController
      applicationDocuments={applicationDocuments}
      layout={layout}
      state={state}
    />
  );
};
