import { InputFragment } from '../generated/graphql';

export const extractDefaultValuesFromInputs = (inputs: InputFragment[]) => {
  return inputs.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.name]: cur.params.defaultValue,
    }),
    {},
  );
};
