import { RouterProvider } from 'react-router-dom';
import guestRouter from '../config/router/guestRouter';
import { signerRouter, managerRouter } from '../config/router/officerRouter';
import { useCookies } from 'react-cookie';
import { Suspense, useMemo } from 'react';
import { useGetCurrentUserQuery } from '../generated/graphql';
import { CircularProgress } from '@mui/material';

const UserProvider = () => {
  const { loading, error, data } = useGetCurrentUserQuery();

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (!data?.officerGetCurrentUser) {
    return null;
  }

  return (
    <RouterProvider
      router={
        data.officerGetCurrentUser.officer?.position.managingServices.length
          ? managerRouter
          : signerRouter
      }
    />
  );
};

export const AuthProvider = () => {
  const [cookies] = useCookies(['session_id']);

  const sessionId = useMemo(() => {
    return cookies.session_id;
  }, [cookies.session_id]);

  if (!sessionId) {
    return (
      <Suspense fallback="loading">
        <RouterProvider router={guestRouter} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback="loading">
      <UserProvider />
    </Suspense>
  );
};
