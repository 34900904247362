import { Box, FormLabel, Typography } from '@mui/material';
import { InputFragment, StudentDataFragment } from '../../generated/graphql';
import { formatInputValue } from './utils';
import { getValueByPath } from '../../utils';

export const StudentCardShow = ({
  inputs,
  data,
}: {
  inputs: InputFragment[];
  data: StudentDataFragment;
}) => {
  return (
    <>
      {inputs
        .filter((input) => {
          if (
            input.params.renderIfPresent &&
            !getValueByPath(data, input.params.renderIfPresent)
          ) {
            return false;
          }

          if (input.params.renderIf) {
            const allConditionsMet = input.params.renderIf.every(
              (condition) =>
                getValueByPath(data, condition.name) === condition.value,
            );

            if (!allConditionsMet) {
              return false;
            }
          }

          return true;
        })
        // .sort((a, b) => a.position - b.position)
        .map((input) => {
          return (
            <Box key={input.name}>
              <FormLabel>{input.label}</FormLabel>
              <Typography>
                {formatInputValue(
                  getValueByPath(data, input.name),
                  input.type,
                  input.params.options,
                )}
              </Typography>
            </Box>
          );
        })}
    </>
  );
};
