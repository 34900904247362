import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { StudentSnapshotFragment } from '../../generated/graphql';
import { VerificationStatus } from '../VerificationStatus/VerificationStatus';
import { StudentCardVerificationBar } from './StudentCardVerificationBar';
import { useState } from 'react';
import { StudentCardShow } from './StudentCardShow';
import { StudentCardEdit } from './StudentCardEdit';

export const StudentCard = ({
  student,
  review,
}: {
  student: StudentSnapshotFragment;
  review?: {
    applicationId: string;
  };
}) => {
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(true);
  };

  const handleShow = () => {
    setEdit(false);
  };

  const renderCorrectionMenu = () => {
    return edit ? (
      <CardActions>
        <Stack spacing={1} width="100%">
          <Button
            type="submit"
            variant="contained"
            fullWidth
            form="STUDENT_FORM"
          >
            Сохнарить
          </Button>
          <Button onClick={handleShow} variant="outlined" fullWidth>
            Отмена
          </Button>
        </Stack>
      </CardActions>
    ) : (
      <CardActions sx={{ pt: 0 }}>
        <Button onClick={handleEdit} variant="outlined" fullWidth color="error">
          Исправить
        </Button>
      </CardActions>
    );
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={1}>
          <Box display="flex" alignItems="center">
            <Typography fontWeight="500">Данные студента</Typography>
            <VerificationStatus verification={student.verification} />
          </Box>
          {student.verification?.error && (
            <Alert
              severity={
                student.verification.error.corrected ? 'warning' : 'error'
              }
            >
              <AlertTitle>
                {student.verification.error.corrected
                  ? 'Обнаруженные ранее ошибки'
                  : 'Обнаружены ошибки'}
              </AlertTitle>
              {student.verification.error.message}
            </Alert>
          )}
          {review && edit ? (
            <StudentCardEdit
              data={student.data}
              inputs={student.inputs}
              applicationId={review.applicationId}
              handleShow={handleShow}
            />
          ) : (
            <StudentCardShow data={student.data} inputs={student.inputs} />
          )}
        </Stack>
      </CardContent>
      {review && (
        <>
          {!edit && (
            <StudentCardVerificationBar
              applicationId={review.applicationId}
              verification={student.verification}
            />
          )}
          {renderCorrectionMenu()}
        </>
      )}
    </Card>
  );
};
