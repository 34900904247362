import {
  StageActionFragment,
  StageTransitionFragment,
} from '../../generated/graphql';
import { StageTransitionButton, StageActionButton } from './components';

export const StageTransitionMenu = ({
  applicationId,
  transitions,
  action,
}: {
  applicationId: string;
  transitions: StageTransitionFragment[];
  action?: StageActionFragment | null;
}) => {
  if (action) {
    return (
      <StageActionButton
        applicationId={applicationId}
        transitions={transitions}
        action={action}
      />
    );
  }

  return transitions.length
    ? transitions.map((transition) => (
        <StageTransitionButton
          key={transition.nextStageId}
          applicationId={applicationId}
          transition={transition}
        />
      ))
    : null;
};
