import {
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { ApplicationDocumentSigningState } from '../../../../../generated/graphql';
import { applicationDocumentSigningStateDict } from '../../utils';
import { APPLICATION_DOCUMENT_SIGNING_STATES } from '../../constants';

export const ApplicationDocumentSigningStateToggle = ({
  state,
  setState,
}: {
  state: ApplicationDocumentSigningState;
  setState: Dispatch<SetStateAction<ApplicationDocumentSigningState>>;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleState = (
    _event: React.MouseEvent<HTMLElement>,
    newState: ApplicationDocumentSigningState | null,
  ) => {
    if (newState != null) {
      setState(newState);
    }
  };

  return (
    <ToggleButtonGroup
      value={state}
      exclusive
      size="small"
      onChange={handleState}
      fullWidth={isMobile}
    >
      {APPLICATION_DOCUMENT_SIGNING_STATES.map(
        (applicationDocumentSigningState) => (
          <ToggleButton
            key={applicationDocumentSigningState}
            value={applicationDocumentSigningState}
            sx={{ px: 2 }}
          >
            {
              applicationDocumentSigningStateDict[
                applicationDocumentSigningState
              ]
            }
          </ToggleButton>
        ),
      )}
    </ToggleButtonGroup>
  );
};
