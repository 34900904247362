import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, IconButton } from '@mui/material';
import { useLogoutMutation } from '../../generated/graphql';

import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';

export const OfficerAppBar = ({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void;
}) => {
  const [logout, { loading, client }] = useLogoutMutation({
    onCompleted() {
      client.resetStore();
    },
  });

  const handleClick = () => {
    logout();
  };

  const { t } = useTranslation();

  return (
    <AppBar
      position="sticky"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      elevation={0}
    >
      <Toolbar>
        <Box width="100%" display="flex" justifyContent="space-between">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" alignSelf="center">
            SQKO Officer
          </Typography>
          <Button color="inherit" onClick={handleClick} disabled={loading}>
            {t('sign-in.exitText')}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
