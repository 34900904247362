import {
  QrFragment,
  useGetQrQuery,
  useSignApplicationDocumentsLoadQrQuery,
  useSignApplicationDocumentsGetQrSignQuery,
  ApplicationDocumentIdIndexInput,
} from '../../generated/graphql';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';

import { QrCode } from './QrCode';
import { LoadingButton } from '@mui/lab';

const ViewMobile = ({ qr, pending }: { qr: QrFragment; pending: boolean }) => {
  const handleClick = () => {
    window.location.href = qr.eGovBusinessLaunchLink;
  };

  return (
    <LoadingButton
      onClick={handleClick}
      fullWidth
      size="large"
      loading={pending}
      loadingIndicator="На подписании…"
      variant={pending ? 'outlined' : 'contained'}
      sx={{
        fontSize: '14px',
      }}
    >
      Подписать в eGov Business
    </LoadingButton>
  );
};

const ViewDesktop = ({ qr, pending }: { qr: QrFragment; pending: boolean }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <QrCode qrCode={qr.qrCode} signing={pending} />
    </Box>
  );
};

const ViewController = ({
  qr,
  pending,
}: {
  qr: QrFragment;
  pending: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return <ViewMobile qr={qr} pending={pending} />;
  }

  return <ViewDesktop qr={qr} pending={pending} />;
};

const GetSignQr = ({
  qr,
  applicationDocuments,
  startSigning,
  handleClose,
}: {
  qr: QrFragment;
  applicationDocuments: ApplicationDocumentIdIndexInput[];
  startSigning: boolean;
  handleClose?: () => void;
}) => {
  const { loading, error, data } = useSignApplicationDocumentsGetQrSignQuery({
    variables: {
      signURL: qr.signURL,
      applicationDocuments,
    },
    skip: !startSigning,
    onCompleted() {
      if (handleClose !== undefined) {
        handleClose();
      }
    },
  });

  if (error) return `Error! ${error.message}`;

  if (data) {
    return null;
  }

  return <ViewController qr={qr} pending={loading} />;
};

const LoadQr = ({
  qr,
  applicationDocuments,
  handleClose,
}: {
  qr: QrFragment;
  applicationDocuments: ApplicationDocumentIdIndexInput[];
  handleClose?: () => void;
}) => {
  const { error, data } = useSignApplicationDocumentsLoadQrQuery({
    variables: {
      dataURL: qr.dataURL,
      applicationDocuments,
    },
  });

  if (error) return `Error! ${error.message}`;

  return (
    <GetSignQr
      qr={qr}
      applicationDocuments={applicationDocuments}
      startSigning={!!data?.officerSignApplicationDocumentsLoadQr}
      handleClose={handleClose}
    />
  );
};

const GetQrLoading = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <LoadingButton
        loading
        loadingIndicator="Подготовка…"
        variant="outlined"
        fullWidth
        size="large"
      >
        Placeholder
      </LoadingButton>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export const QrMultipleSigning = ({
  applicationDocuments,
  handleClose,
}: {
  applicationDocuments: ApplicationDocumentIdIndexInput[];
  handleClose?: () => void;
}) => {
  const { loading, error, data } = useGetQrQuery({
    fetchPolicy: 'network-only',
    pollInterval: 60 * 1000,
  });

  if (loading) return <GetQrLoading />;
  if (error) return `Error! ${JSON.stringify(error)}`;
  if (!data) return 'Smth went wrong';

  return (
    <LoadQr
      qr={data.getQr}
      applicationDocuments={applicationDocuments}
      handleClose={handleClose}
    />
  );
};
