import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { InputProps } from '../../InputController';

export const InputTextarea = ({
  input: {
    label,
    name,
    rules,
    params: { defaultValue },
  },
  show,
  disabled,
  control,
  getValues,
}: InputProps) => {
  if (show) {
    return (
      <>
        <FormLabel component="legend">{label}</FormLabel>
        <div>{getValues(name)}</div>
      </>
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={Object.fromEntries(
        Object.entries(rules).filter(([, value]) => value !== null),
      )}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          fullWidth
          multiline
          minRows={3}
          maxRows={12}
          error={!!fieldState.error}
          label={label}
          helperText={fieldState.error?.message}
          disabled={disabled}
          required={rules.required?.value}
        />
      )}
    />
  );
};
