/* eslint-disable @typescript-eslint/no-explicit-any */
import { format, parseISO } from 'date-fns';
import { InputType } from '../../generated/graphql';
import { ru } from 'date-fns/locale';

const { Date } = InputType;

export const formatInputValue = (value: any, inputType: InputType): string => {
  let result = '';

  switch (inputType) {
    case Date:
      result = format(parseISO(value), 'd MMMM y', {
        locale: ru,
      });
      break;
    default:
      break;
  }

  return result;
};
