import { Box, Typography, Stack } from '@mui/material';
import { ApplicationFragment } from '../../../generated/graphql';
import {
  UserCard,
  FormCard,
  StudentCard,
  ApplicationHistory,
  StageTransitionMenu,
  ApplicationDocuments,
  StudentDocumentCard,
  ApplicationDormInfo,
} from '../../../common';
import { format, formatISO } from 'date-fns';
import { ru } from 'date-fns/locale/ru';

export const ApplicationShowView = ({
  application,
}: {
  application: ApplicationFragment;
}) => {
  return (
    <Box>
      <Stack bgcolor="#f1f2f2" spacing={1} pb={1}>
        <Stack spacing={1} p={2} bgcolor="white">
          <Typography fontWeight="500">Основное</Typography>
          <Box>
            <Typography>Услуга</Typography>
            <Typography>{application.data.service.name}</Typography>
          </Box>
          <Box>
            <Typography>Статус</Typography>
            <Typography>
              {[
                application.stage.name,
                application.updates.find(
                  (d) => d.stageId === application.stage.id,
                )?.alertMessage,
              ]
                .filter((str) => str)
                .join(': ')}
            </Typography>
          </Box>
          <Box>
            <Typography>Дата создания</Typography>
            <Typography>
              {format(formatISO(application.createdAt), 'd MMMM y, HH:mm', {
                locale: ru,
              })}
            </Typography>
          </Box>
        </Stack>

        {application.dormApplication && (
          <ApplicationDormInfo dormApplication={application.dormApplication} />
        )}

        <ApplicationDocuments applicationDocuments={application.documents} />

        <UserCard
          user={application.data.user}
          phoneNumber={application.student.user.phoneNumber}
        />

        <StudentCard student={application.data.student} />

        <FormCard
          form={application.data.form}
          inputs={application.service.inputs}
        />

        {application.data.studentDocuments.map((studentDocument) => (
          <StudentDocumentCard
            key={studentDocument.data.category}
            studentDocument={studentDocument}
          />
        ))}

        <ApplicationHistory updates={application.updates} />
      </Stack>

      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          bgcolor: '#ffffff',
        }}
      >
        <StageTransitionMenu
          applicationId={application.id}
          transitions={application.stage.transitions}
          action={application.stage.action}
        />
      </Box>
    </Box>
  );
};
