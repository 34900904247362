import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { ApplicationDocumentLayoutType } from '.';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

export const ApplicationDocumentLayoutToggle = ({
  layout,
  setLayout,
}: {
  layout: ApplicationDocumentLayoutType;
  setLayout: Dispatch<SetStateAction<ApplicationDocumentLayoutType>>;
}) => {
  const handleState = (
    _event: React.MouseEvent<HTMLElement>,
    newLayout: ApplicationDocumentLayoutType | null,
  ) => {
    if (newLayout != null) {
      setLayout(newLayout);
    }
  };

  return (
    <ToggleButtonGroup
      value={layout}
      exclusive
      size="small"
      onChange={handleState}
    >
      <ToggleButton value="TABLE" sx={{ px: 2 }}>
        <ViewListIcon />
      </ToggleButton>
      <ToggleButton value="CARD" sx={{ px: 2 }}>
        <ViewModuleIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
